import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DDCService } from "../../Service/User/diamond-ddc/diamond-ddc.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ImgVideoComponent } from "../img-video/img-video.component";
import { TabBarService } from "../../Service/tab-bar.service";
import {TranslateService} from '@ngx-translate/core';
import { ICellRendererParams } from 'ag-grid-community';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';
import * as $ from "jquery";

@Component({
  selector: 'app-diamond-ddc',
  templateUrl: './diamond-ddc.component.html',
  styleUrls: ['./diamond-ddc.component.css']
})
export class DiamondDdcComponent implements OnInit {

  decodeHelper = new JwtHelperService();
  decodedTkn = this.decodeHelper.decodeToken(localStorage.getItem('token'));

  apiUrl = environment.BaseUrl;

  selectedRow = [];


  public columnDefs;
  public gridApi;
  public gridColumnApi;
  public defaultColDef;
  public rowSelection;
  public isRowSelectable;
  set_lang:any='';
  constructor(
    private spinner: NgxSpinnerService,
    private DDCSer: DDCService,
    private matDialog: MatDialog,
    private TabBarServ: TabBarService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {

    translate.addLangs(['en', 'ch']);
    translate.setDefaultLang('en');
    this.set_lang=sessionStorage.getItem('set_lang');
    if(this.set_lang == '' || this.set_lang == undefined || this.set_lang == null)
    {
      translate.setDefaultLang('en');
    }else{
      translate.setDefaultLang(this.set_lang);
    }

    this.translate.onLangChange.subscribe(() => {
      this.gridApi.refreshHeader();
    })


    this.columnDefs = [
      {
        width: 28,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        resizable: false,
        filter: false,
        sortable: false
      },
      {
        headerName: "Status",
        field: "DStatus",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 82,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },

      {
        headerName: "StoneID",
        field: "StockNo",
        width: 114,
        headerClass: "text-left",
        cellStyle: function (params){
          if(params.data.DiamondStatus == 1){
             return { "text-align":"center" ,}
          }else{
            return { "text-align":"center" ,"background":'#FF99CC;'}
          }
        },
        cellRenderer: function (params) {
          if (params.data.DiamondStatus == 1) {
            return `<span style="color:#007bff;cursor:pointer" data-action-type="StoneDet">${params.data.StockNo}</span>`;
          } else {
            return `<span style="color:#007bff;cursor:pointer;background-color:#FF99CC;" data-action-type="StoneDet">${params.data.StockNo}</span>`;
          }
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Details",
        headerClass: "text-left",
        width: 85,
        resizable: true,
        filter: false,
        sortable: false,
        cellRenderer: function (params) {
          var html =
            '<span class="det_val"><i class="icon-camera" data-action-type="photo" ></i>&nbsp;<i class="icon-ico_Movie" data-action-type="video"></i>&nbsp;<i class="icon-certificate" data-action-type="certificat"></i></span>';
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Cert",
        field: "Certi",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 64,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Loc",
        field: "Location",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 55,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Shape",
        field: "Shape",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 66,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Carat",
        field: "Carat",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 62,
        resizable: true,
        cellRenderer: function (params) {
          var html = "<span>" + params.data.Carat.toFixed(2) + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Color",
        field: "Color",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 63,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Clarity",
        field: "Clarity",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 66,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Cut",
        field: "Cut",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 66,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Pol",
        field: "Polish",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 49,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Sym",
        field: "Symm",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 53,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Flo",
        field: "Flo",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 50,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "ORap",
        field: "RapRate",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 64,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },


      {
        headerName: "ROff",
        field: "Discount",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 68,
        resizable: true,
        cellRenderer: function (params) {
          // return html;
          return "<span style='font-weight:bold'>" +((params.data.Discount)? params.data.Discount.toFixed(2) : 0.00)  + "</span>";

        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "PerCrtRate",
        field: "PricePerCarat",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 70,
        resizable: true,
        cellRenderer: function (params) {
          // return html;
          if (params.data.Cart_Amount < params.data.Amount) {
            return "<span style='color:red;font-weight:bold' >" + params.data.PricePerCarat.toFixed(2) + "</span>";
          } else if (params.data.Cart_Amount > params.data.Amount) {
            return "<span style='color:green;font-weight:bold' >" + params.data.PricePerCarat.toFixed(2) + "</span>";
          } else {
            return "<span style='font-weight:bold'>" + params.data.PricePerCarat.toFixed(2) + "</span>";
          }
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Amount",
        field: "Amount",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 78,
        resizable: true,
        cellRenderer: function (params) {
          // return html;
          if (params.data.Cart_Amount < params.data.Amount) {
            return "<span style='color:red;font-weight:bold' >" + params.data.Amount.toFixed(2) + "</span>";
          } else if (params.data.Cart_Amount > params.data.Amount) {
            return "<span style='color:green;font-weight:bold' >" + params.data.Amount.toFixed(2) + "</span>";
          } else {
            return "<span style='font-weight:bold'>" + params.data.Amount.toFixed(2) + "</span>";
          }
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "ReportNo",
        field: "ReptNo",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 85,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Measurement",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 110,
        resizable: true,
        cellRenderer: function (params) {
          var html = `<span>${params.data.DMin}-${params.data.DMax}*${params.data.DHeight}</span>`;
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Table",
        field: "Tbl",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 58,
        resizable: true,
        cellRenderer: function (params) {
          var html = "<span>" + params.data.Tbl + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Depth",
        field: "TableDepth",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 66,
        resizable: true,
        cellRenderer: function (params) {
          var html = "<span>" + params.data.TableDepth + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "LU",
        field: "Luster",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 47,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Brn",
        field: "Brown",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 55,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "TB",
        field: "TblBlack",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 47,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "SB",
        field: "SideBlack",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 48,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "TI",
        field: "TableIncluction",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 64,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Culet",
        field: "Culet",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 60,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "GirPer",
        field: "GirdlePer",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 59,
        resizable: true,
        cellRenderer: function (params) {
          var html = "<span>" + params.data.GirdlePer + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "HA",
        field: "HNA",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 60,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      }
    ];

    this.defaultColDef = {
      resizable: true,
      filter: false,
      sortable: true
    };

    this.rowSelection = "multiple";
  }

  ngOnInit() {
    $("body").removeClass("diamond-search");
    this.TabBarServ.sharedData$.subscribe(sharedData => {
      if (sharedData == "DDC") {
      }
    });
    setTimeout(() => {
      if(($('.sidebar-container').hasClass('sidebar-is-expanded')) == true){
        $('#imgLogo').attr("src","../../../assets/PSHNew/img/psh-dashbaord-big-logo.png");
        $("#imgLogo").addClass("largeLogo");
      }else{
        $('#imgLogo').attr("src","../../../assets/PSHNew/img/psh-dashbaord-small-logo.png");
        $("#imgLogo").removeClass("largeLogo");
      } 
    }, 700);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.LoadGridData()
  }

  onSelectionChanged(event: any) {
    this.selectedRow = event.api.getSelectedRows();
  }

  onGridRowClicked(eve: any) {
    if (eve.event.target !== undefined) {
      let actionType = eve.event.target.getAttribute("data-action-type");

      if (actionType == "photo") {
        this.OpenDetailModal("photo", eve.data.ImageUrl);
      } else if (actionType == "video") {
        this.OpenDetailModal("video", eve.data.VideoUrl);
      } else if (actionType == "certificat") {
        this.OpenDetailModal("certificat", eve.data.CertPDFUrl);
      } else if (actionType == "StoneDet") {
        localStorage.removeItem("SD");
        localStorage.setItem("SD", eve.data.StockNo);
        localStorage.setItem(eve.data.StockNo, JSON.stringify(eve.data))
        this.TabBarServ.setData("Stone Detail (" + eve.data.StockNo + ")");
      }
    }
  }



  // detail modal starts
  matDialogRefDteails: MatDialogRef<ImgVideoComponent>;

  OpenDetailModal(ModalTopic, FilePath) {
    this.matDialogRefDteails = this.matDialog.open(ImgVideoComponent, {
      data: { ModalTopic, FilePath },
      disableClose: false
    });
  }
  // detail modal ends



  LoadGridData() {
    this.spinner.show()
    this.DDCSer.DDCFill({SessionId:this.decodedTkn.sessionId}).subscribe(ResultRes => {
      try {
        if (ResultRes.success == 1) {
          this.gridApi.setRowData(ResultRes.data);
          this.spinner.hide()
        } else {
          this.spinner.hide()
        }
      } catch (error) {
        console.log(error);
        this.spinner.hide()
      }
    });
  }

  public localizeHeader(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.headerName;
    return this.translate.instant(headerIdentifier);
  }

  excelExport(){
    this.spinner.show();

    if(this.selectedRow.length == 0){
      this.spinner.hide()
      this.toastr.warning("Select at least one stone")
      return
    }

    var DDCId = this.selectedRow.map(function(val) {
      return val.DDCId;
    }).join(',');

    var mapForm = document.createElement("form");
    mapForm.method = "POST"; // or "post" if appropriate
    mapForm.action = this.apiUrl + 'DDC/ExcelExport';

    var SLmapInput = document.createElement("input");
    SLmapInput.type = "hidden";
    SLmapInput.name = "DDCId";
    SLmapInput.setAttribute("value", DDCId);
    mapForm.appendChild(SLmapInput);

    var UIDmapInput = document.createElement("input");
    UIDmapInput.type = "hidden";
    UIDmapInput.name = "UserID";
    UIDmapInput.setAttribute("value", this.decodedTkn.UserID);
    mapForm.appendChild(UIDmapInput);

    document.body.appendChild(mapForm);
    mapForm.submit();

    this.spinner.hide()
  }

}
