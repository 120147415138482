import { Component, OnInit, Output, EventEmitter,ViewChild } from '@angular/core';
import * as $ from "jquery";
import { TranslateService } from '@ngx-translate/core';
import { Router } from "@angular/router"
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { RegisterService } from './../Service/register.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.css']
})

export class QuotationComponent implements OnInit {
   
  Year:any=new Date().getFullYear()

  @Output() langEvent = new EventEmitter<string>();
  
  set_lang: any = '';
  showElement: any = false;
  FirstName:any=''
  LastName:any=''
  MobileNumber:any=''
  InterestedCategory:any=''
  Remark:any=''
  EmailId = ''
  CompanyName = ''
  CompanyAddress = ''
  PRD_IMGPATH:any=''; //
  PRD_IMG_EXT:any='';//
  prdImgPathHTML:any='';
  constructor(
    private translate: TranslateService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private RegisterServ : RegisterService,
    private sanitizer: DomSanitizer,
  ) {
    translate.addLangs(['en', 'ch']);
    translate.setDefaultLang('en');
    this.set_lang = sessionStorage.getItem('set_lang');
    if (this.set_lang == '' || this.set_lang == undefined || this.set_lang == null) {
      translate.setDefaultLang('en');
    } else {
      translate.setDefaultLang(this.set_lang);
    }
  }

  public loadJsFile(url) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  public loadCSSFile(url) {
    let node = document.createElement('link');
    node.href = url;
    node.type = 'text/css';
    node.type = 'stylesheet';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  ngOnInit() {
    $("body").removeClass("diamond-search");
    $.noConflict();
    // this.loadJsFile("https://code.jquery.com/jquery-1.12.4.min.js");
    // this.loadJsFile("../../assets/BeforLogin/js/smoothscroll.js");
    // this.loadJsFile("../../assets/BeforLogin/js/popper.min.js");
    // this.loadJsFile("../../assets/BeforLogin/js/animsition.min.js");
    // this.loadJsFile("../../assets/BeforLogin/js/owl.carousel.min.js");
    // this.loadJsFile("../../assets/BeforLogin/js/wow.min.js");
    // this.loadJsFile("../../assets/BeforLogin/js/jquery.pagepiling.min.js");
    // this.loadJsFile("../../assets/BeforLogin/js/isotope.pkgd.min.js");
    // this.loadJsFile("../../assets/BeforLogin/js/jquery.fancybox.min.js");
    // this.loadJsFile("../../assets/BeforLogin/js/TweenMax.min.js");
    // this.loadJsFile("../../assets/BeforLogin/js/ScrollMagic.min.js");
    //this.loadJsFile("../../assets/BeforLogin/js/animation.gsap.min.js"); // already commented
    // this.loadJsFile("../../assets/BeforLogin/js/jquery.viewport.js");
    // this.loadJsFile("../../assets/BeforLogin/js/jquery.countdown.min.js");
    // this.loadJsFile("../../assets/BeforLogin/js/script.js");


  }

  ngAfterViewInit(){
    this.loadJsFile("../../assets/PSHNew/js/script.js");
  }


  // scrollToID(id, speed) {
  //   var offSet = 0;
  //   var targetOffset = $(id).offset().top - offSet;
  //   var mainNav = $('#main-nav');
  //   $('html,body').animate({ scrollTop: targetOffset }, speed);
  //   if (mainNav.hasClass("open")) {
  //     mainNav.css("height", "1px").removeClass("in").addClass("collapse");
  //     mainNav.removeClass("open");
  //   }
  // }


langtranslator(lan)
  {
    this.set_lang =lan;     sessionStorage.setItem('set_lang', lan);
    this.translate.use(lan);
    this.langEvent.next(lan);
  }

  redirectToLogin() {
    this.router.navigate(['/login'])
  }

  redirectToProduct() {
    this.router.navigate(['/products'])
  }

  redirectToQuotation(){
    this.router.navigate(['/quotation'])
  }

  redirectToIndex(){
    this.router.navigate(['/index'])
  }

  redirectToAboutUs(){
    this.router.navigate(['/aboutus'])
  }

  redirectToContactUs(){
    this.router.navigate(['/contact'])
  }

  redirectToTerms(){
    this.router.navigate(['/terms'])
  }
  Save(){
    if(this.FirstName.trim() == ""){
      this.toastr.warning("Enter First Name");
      return;
    }
    if(this.LastName.trim() == ""){
      this.toastr.warning("Enter Lase Name");
      return;
    }
    if(this.MobileNumber.trim() == ""){
      this.toastr.warning("Enter Mobile number");
      return;
    }
    if(this.InterestedCategory.trim() == ""){
      this.toastr.warning("Select interested category");
      return;
    }
    if(this.Remark.trim() == ""){
      this.toastr.warning("Enter Remark");
      return;
    }
    if(this.EmailId.trim() == ""){
      this.toastr.warning("Enter Email address");
      return;
    }
    if(this.CompanyName.trim() == ""){
      this.toastr.warning("Enter Company Name");
      return;
    }
    if(this.CompanyAddress.trim() == ""){
      this.toastr.warning("Enter Company address");
      return;
    }
    let SaveObj={
      FirstName:this.FirstName.trim(),
      LastName:this.LastName,
      MobileNumber:this.MobileNumber ? this.MobileNumber : '',
      InterestedCategory:this.InterestedCategory,
      Remark:this.Remark,
      EmailId : this.EmailId,
      CompanyName : this.CompanyName,
      CompanyAddress : this.CompanyAddress,
      PRD_IMGPATH : this.PRD_IMGPATH ? this.PRD_IMGPATH : '',
      PRD_IMG_EXT : this.PRD_IMG_EXT ? this.PRD_IMG_EXT : '',
    }
    this.spinner.show()
    this.RegisterServ.QuotationSave(SaveObj).subscribe((SRes)=>{
      try{
        if(SRes.success == true){
          this.spinner.hide();
          this.toastr.success('Saved successfully');
            this.Reset()
        }
      }catch(err){
          this.spinner.hide()
          this.toastr.error(err)
        }
      })
  }

  uploadImage(event:any){
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.PRD_IMGPATH = reader.result
      this.PRD_IMG_EXT = file.name.split('.').pop();
      this.prdImgPathHTML = this.sanitizer.bypassSecurityTrustHtml(`<img src="${this.PRD_IMGPATH}" class="img-fluid" alt="" style="height: 200px; width:200px; object-fit: cover;">`)
      this.fileReset()
    };
  }

  fileReset() {
    // if(this.Import.nativeElement.value){
    //   this.Import.nativeElement.value = "";
    // }
  }

  Reset(){  
    this.FirstName=''
    this.LastName=''
    this.MobileNumber=''
    this.InterestedCategory = ''
    this.Remark = '',
    this.EmailId = '',
    this.CompanyName = '',
    this.CompanyAddress = ''
  }

  selectSection(selectType){
   this.InterestedCategory = selectType; 
  }
}