import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { OrderService } from "../../Service/User/diamond-order/order.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ImgVideoComponent } from "../img-video/img-video.component";
import { TabBarService } from "../../Service/tab-bar.service";
import {TranslateService} from '@ngx-translate/core';
import { ICellRendererParams } from 'ag-grid-community';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as $ from "jquery";

@Component({
  selector: 'app-order-summary-detail',
  templateUrl: './order-summary-detail.component.html',
  styleUrls: ['./order-summary-detail.component.css']
})
export class OrderSummaryDetailComponent implements OnInit {
  InvoiceNo:any=''
  public columnDefs;
  public gridApi;
  public gridColumnApi;
  public defaultColDef;
  public rowSelection;
  public isRowSelectable;
  decodeHelper = new JwtHelperService();
  decodedTkn = this.decodeHelper.decodeToken(localStorage.getItem('token'));
  set_lang = "";
  constructor(
    private spinner: NgxSpinnerService,
    private OrderSer: OrderService,
    private matDialog: MatDialog,
    private TabBarServ: TabBarService,
    private toastr : ToastrService ,
    private translate: TranslateService
  ) {

    translate.addLangs(['en', 'ch']);
    translate.setDefaultLang('en');
    this.set_lang=sessionStorage.getItem('set_lang');
    if(this.set_lang == '' || this.set_lang == undefined || this.set_lang == null)
    {
      translate.setDefaultLang('en');
    }else{
      translate.setDefaultLang(this.set_lang);
    }

    this.translate.onLangChange.subscribe(() => {
      this.gridApi.refreshHeader();
    })


    this.columnDefs = [
      {
        width: 28,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        resizable: false,
        filter: false,
        sortable: false
      },
      {
        headerName: "StoneID",
        field: "StockNo",
        width: 114,
        headerClass: "text-left" ,
        headerValueGetter: this.localizeHeader.bind(this)
      },

      {
        headerName: "Cert",
        field: "Certi",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 64,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Loc",
        field: "Location",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 55,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Shape",
        field: "Shape",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 66,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Carat",
        field: "Carat",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 62,
        resizable: true,
        cellRenderer: function(params) {
          var html = "<span>" + params.data.Carat.toFixed(2) + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Color",
        field: "Color",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 63,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Clarity",
        field: "Clarity",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 66,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Cut",
        field: "Cut",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 49,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Pol",
        field: "Polish",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 49,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Sym",
        field: "Symm",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 53,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Flo",
        field: "Flo",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 50,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "ORap",
        field: "RapRate",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 64,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "ROff",
        field: "Discount",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 68,
        resizable: true,
        cellRenderer: function(params) {
          var html = "<span>" + ((params.data.Discount)? params.data.Discount.toFixed(2) : 0.00)  + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "PerCrtRate",
        field: "PricePerCarat",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 70,
        resizable: true,
        cellRenderer: function(params) {
          var html =
            "<span>" + params.data.PricePerCarat.toFixed(2) + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Amount",
        field: "Amount",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 78,
        resizable: true,
        cellRenderer: function(params) {
          var html = "<span>" + params.data.Amount.toFixed(2) + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "ReportNo",
        field: "ReptNo",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 85,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Measurement",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 110,
        resizable: true,
        cellRenderer: function(params) {
          var html = `<span>${params.data.DMin}-${params.data.DMax}*${params.data.DHeight}</span>`;
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Table",
        field: "Tbl",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 58,
        resizable: true,
        cellRenderer: function(params) {
          var html = "<span>" + params.data.Tbl + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Depth",
        field: "TableDepth",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 66,
        resizable: true,
        cellRenderer: function(params) {
          var html = "<span>" + params.data.TableDepth + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "LU",
        field: "Luster",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 47,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Brn",
        field: "Brown",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 55,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "TB",
        field: "TblBlack",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 47,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "SB",
        field: "SideBlack",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 48,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "TI",
        field: "TableIncluction",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 64,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Culet",
        field: "Culet",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 60,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "GirPer",
        field: "GirdlePer",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 59,
        resizable: true,
        cellRenderer: function(params) {
          var html = "<span>" + params.data.GirdlePer + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "HA",
        field: "HNA",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 60,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      }
    ];

    this.defaultColDef = {
      resizable: true,
      filter: false,
      sortable: true
    };

    this.rowSelection = "multiple";
  }

  ngOnInit() {
    $("body").removeClass("diamond-search");
    this.InvoiceNo = localStorage.getItem("InvoiceID");
    setTimeout(() => {
      if(($('.sidebar-container').hasClass('sidebar-is-expanded')) == true){
        $('#imgLogo').attr("src","../../../assets/PSHNew/img/psh-dashbaord-big-logo.png");
        $("#imgLogo").addClass("largeLogo");
      }else{
        $('#imgLogo').attr("src","../../../assets/PSHNew/img/psh-dashbaord-small-logo.png");
        $("#imgLogo").removeClass("largeLogo");
      } 
    }, 700);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.LoadGridData()
  }

  onSelectionChanged(event: any) {

  }

  onGridRowClicked(eve: any) {}

  LoadGridData() {
    this.spinner.show()
    var payload ={
      "InvoiceId":this.InvoiceNo,
      "SessionId":this.decodedTkn.sessionId
    }
    this.OrderSer.OrderDetailFill(payload).subscribe(ResultRes => {
      try {
        if (ResultRes.success == 1) {
          this.gridApi.setRowData(ResultRes.data);
          this.spinner.hide()
        } else {
          this.spinner.hide()
        }
      } catch (error) {
        console.log(error);
        this.spinner.hide()
      }
    });
  }


  public localizeHeader(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.headerName;
    return this.translate.instant(headerIdentifier);
  }

}
