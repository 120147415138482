import { Component, OnInit } from '@angular/core';

import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { JwtHelperService } from '@auth0/angular-jwt';
import swal from 'sweetalert';

import { DiamondDemandService } from '../../Service/User/diamond-demand/diamond-demand.service'
import * as $ from "jquery";

@Component({
  selector: 'app-stock-demand',
  templateUrl: './stock-demand.component.html',
  styleUrls: ['./stock-demand.component.css']
})
export class StockDemandComponent implements OnInit {


  decodeHelper = new JwtHelperService();
  decodedTkn = this.decodeHelper.decodeToken(localStorage.getItem('token'));


  public columnDefs;
  public gridApi;
  public gridColumnApi;
  public defaultColDef;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private DiamondDemandServ : DiamondDemandService,
  ) {
    this.columnDefs = [
      {
        headerName: "Search Name",
        field: "demand_name",
        width: 114,
        headerClass: "text-center",
        cellStyle: { "text-align": "center" },
        resizable: true,
      },
      {
        headerName: "Shape",
        field: "shape_name",
        headerClass: "text-center",
        cellStyle: { "text-align": "center" },
        width: 64,
        resizable: true,
      },
      {
        headerName: "From Carat",
        field: "FROM_CARAT",
        headerClass: "text-center",
        cellStyle: { "text-align": "center" },
        width: 64,
        resizable: true,
      },
      {
        headerName: "To Carat",
        field: "TO_CARAT",
        headerClass: "text-center",
        cellStyle: { "text-align": "center" },
        width: 64,
        resizable: true,
      },
      {
        headerName: "Size",
        field: "Size_name",
        headerClass: "text-center",
        cellStyle: { "text-align": "center" },
        width: 64,
        resizable: true,
      },
      {
        headerName: "Color",
        field: "color_name",
        headerClass: "text-center",
        cellStyle: { "text-align": "center" },
        width: 64,
        resizable: true,
      },
      {
        headerName: "Clarity",
        field: "clarity_name",
        headerClass: "text-center",
        cellStyle: { "text-align": "center" },
        width: 64,
        resizable: true,
      },
      {
        headerName: "Cut",
        field: "cut_name",
        headerClass: "text-center",
        cellStyle: { "text-align": "center" },
        width: 64,
        resizable: true,
      },
      {
        headerName: "Polish",
        field: "polish_name",
        headerClass: "text-center",
        cellStyle: { "text-align": "center" },
        width: 64,
        resizable: true,
      },
      {
        headerName: "Symmenrty",
        field: "symm_name",
        headerClass: "text-center",
        cellStyle: { "text-align": "center" },
        width: 64,
        resizable: true,
      },
      {
        headerName: "Fluorescence",
        field: "Flo_name",
        headerClass: "text-center",
        cellStyle: { "text-align": "center" },
        width: 64,
        resizable: true,
      },
      {
        headerName: "Action",
        width: 114,
        headerClass: "text-center",
        resizable: true,
        cellStyle: { "text-align": "center" },
        cellRenderer: function(params) {
          if (params.data.id) {
            return `<span style="color:#007bff;cursor:pointer;" ><i class='icon-remove' data-action-type="SaveSearchDelete"></i></span>`;
          }
        }
      },
    ]
   }

  ngOnInit() {
    setTimeout(() => {
      if(($('.sidebar-container').hasClass('sidebar-is-expanded')) == true){
        $('#imgLogo').attr("src","../../../assets/PSHNew/img/psh-dashbaord-big-logo.png");
        $("#imgLogo").addClass("largeLogo");
      }else{
        $('#imgLogo').attr("src","../../../assets/PSHNew/img/psh-dashbaord-small-logo.png");
        $("#imgLogo").removeClass("largeLogo");
      } 
    }, 700);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.LoadGridData()
  }

  LoadGridData(){
    this.spinner.show()
    this.DiamondDemandServ.DiamondDemandFill({
      UserId:this.decodedTkn.UserID,
      SESSION_ID:this.decodedTkn.sessionId,
    }).subscribe((FRes)=>{
      this.spinner.hide()
      try {
        if(FRes.success==true){
          this.gridApi.setRowData(FRes.data)
          this.gridApi.sizeColumnsToFit()
        }else{
          this.gridApi.setRowData([])
          this.toastr.warning('Something went wrong while displaying this search search')
        }
      } catch (error) {
        this.toastr.error(error)
      }
    })
  }

  onGridRowClicked(eve: any) {
    if (eve.event.target !== undefined) {
      let actionType = eve.event.target.getAttribute("data-action-type");

      if (actionType == "SaveSearchOpe") {

      }else if (actionType == 'SaveSearchDelete'){
        swal({
          title: "Are you sure?",
          text: "Are you sure that you want to delete this Demand?",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete ) => {

          if (willDelete) {
            this.spinner.show()
            this.DiamondDemandServ.RemoveFromDiamondDemand({
              DemandId:eve.data.id,
              SESSION_ID:this.decodedTkn.sessionId
            }).subscribe((DRes)=>{
              this.spinner.hide()
              try {
                if(DRes.success == true){
                  this.toastr.success("Save search delete successfully.")
                  this.LoadGridData()
                }else{
                  this.toastr.warning('Something went wrong while delete this search search')
                }
              } catch (error) {
                this.toastr.warning(error)
              }
            })
          }
        });
      }
    }
  }

}
