import { Component, OnInit ,Inject} from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-img-video',
  templateUrl: './img-video.component.html',
  styleUrls: ['./img-video.component.css']
})
export class ImgVideoComponent implements OnInit {

  certiIsImage = false;
  ModalTopic: any;
  FilePath: any;
  html:any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _mdr: MatDialogRef<ImgVideoComponent>,
    private sanitizer: DomSanitizer
    ) {
      this.ModalTopic = data.ModalTopic;
      this.FilePath = data.FilePath;

      if(this.ModalTopic=='certificat'){
        let certiExt = this.FilePath.split('.').pop();
        if (certiExt.toUpperCase() == 'PDF') {
          this.certiIsImage = false;
        } else {
          this.certiIsImage = true;
        }
      }
    }

  ngOnInit(): void {

    if(this.ModalTopic=='video'){
      this.html = this.sanitizer.bypassSecurityTrustHtml('<iframe   height="auto" src="'+this.FilePath+'" class="det_vdeo"></iframe>') ;
    }else if(this.ModalTopic=='certificat'){

      if (this.FilePath.split('.').pop().toUpperCase() == 'PDF') {
        this.html = this.sanitizer.bypassSecurityTrustHtml('<iframe height="auto" src="'+this.FilePath+'" class="det_vdeo"></iframe>') ;
      } else {
        this.html = this.sanitizer.bypassSecurityTrustHtml('<img   height="auto" class="img-responsive stn_img" src="'+this.FilePath+'">') ;
      }
    }else if(this.ModalTopic=='photo'){
      this.html = this.sanitizer.bypassSecurityTrustHtml('<img  height="auto" class="img-responsive stn_img" src="'+this.FilePath+'">') ;
    }
  }


  CloseDialog() {
    this._mdr.close(false)
  }
}
