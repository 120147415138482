import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as $ from "jquery";
import { TranslateService } from '@ngx-translate/core';
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { RegisterService } from './../Service/register.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  Year:any=new Date().getFullYear()

  @Output() langEvent = new EventEmitter<string>();
  set_lang: any = '';
  showElement: any = false;
  FirstName:any=''
  LastName:any=''
  MobileNumber:any=''
  InterestedCategory:any=''
  Remark:any=''
  EmailId = ''
  CompanyName = ''
  CompanyAddress = ''

  constructor(
    private translate: TranslateService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private RegisterServ : RegisterService,
  ) {
    translate.addLangs(['en', 'ch']);
    translate.setDefaultLang('en');
    this.set_lang = sessionStorage.getItem('set_lang');
    if (this.set_lang == '' || this.set_lang == undefined || this.set_lang == null) {
      translate.setDefaultLang('en');
    } else {
      translate.setDefaultLang(this.set_lang);
    }
  }

  public loadJsFile(url) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  public loadCSSFile(url) {
    let node = document.createElement('link');
    node.href = url;
    node.type = 'text/css';
    node.type = 'stylesheet';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  ngOnInit() {
    $("body").removeClass("diamond-search");
    $.noConflict();
    
  }

  ngAfterViewInit(){
    this.loadJsFile("../../assets/PSHNew/js/script.js");
  }
 


langtranslator(lan)
  {
    this.set_lang =lan;     sessionStorage.setItem('set_lang', lan);
    this.translate.use(lan);
    this.langEvent.next(lan);
  }

  
  redirectToLogin() {
    this.router.navigate(['/login'])
  }

  redirectToProduct() {
    this.router.navigate(['/products'])
  }

  redirectToQuotation(){
    this.router.navigate(['/quotation'])
  }

  redirectToIndex(){
    this.router.navigate(['/index'])
  }

  redirectToAboutUs(){
    this.router.navigate(['/aboutus'])
  }

  redirectToContactUs(){
    this.router.navigate(['/contact'])
  }

  redirectToTerms(){
    this.router.navigate(['/terms'])
  }
  Save(){

    if(this.FirstName.trim() == ""){
      this.toastr.warning("Enter First Name");
      return;
    }
    if(this.LastName.trim() == ""){
      this.toastr.warning("Enter Lase Name");
      return;
    }
    if(this.MobileNumber.trim() == ""){
      this.toastr.warning("Enter Mobile number");
      return;
    }
    if(this.InterestedCategory.trim() == ""){
      this.toastr.warning("Select interested category");
      return;
    }
    if(this.Remark.trim() == ""){
      this.toastr.warning("Enter Remark");
      return;
    }
    if(this.EmailId.trim() == ""){
      this.toastr.warning("Enter Email address");
      return;
    }
    if(this.CompanyName.trim() == ""){
      this.toastr.warning("Enter Company Name");
      return;
    }
    if(this.CompanyAddress.trim() == ""){
      this.toastr.warning("Enter Company address");
      return;
    }
    let SaveObj={
      FirstName:this.FirstName.trim(),
      LastName:this.LastName,
      MobileNumber:this.MobileNumber ? this.MobileNumber : '',
      InterestedCategory:this.InterestedCategory,
      Remark:this.Remark,
      EmailId : this.EmailId,
      CompanyName : this.CompanyName,
      CompanyAddress : this.CompanyAddress
    }
    this.spinner.show()
    this.RegisterServ.ContactUsSave(SaveObj).subscribe((SRes)=>{
      try{
        if(SRes.success == true){
          this.spinner.hide();
          this.toastr.success('Saved successfully');
            this.Reset()
        }
      }catch(err){
          this.spinner.hide()
          this.toastr.error(err)
        }
      })
  }

  Reset(){  
    this.FirstName=''
    this.LastName=''
    this.MobileNumber=''
    this.InterestedCategory = ''
    this.Remark = '',
    this.EmailId = '',
    this.CompanyName = '',
    this.CompanyAddress = ''
  }
}
