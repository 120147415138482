import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as $ from "jquery";
import { TranslateService } from '@ngx-translate/core';
import { Router } from "@angular/router"


@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

  Year:any=new Date().getFullYear()

  @Output() langEvent = new EventEmitter<string>();
  set_lang: any = '';
  showElement: any = false;
  constructor(
    private translate: TranslateService,
    private router: Router
  ) {
    translate.addLangs(['en', 'ch']);
    translate.setDefaultLang('en');
    this.set_lang = sessionStorage.getItem('set_lang');
    if (this.set_lang == '' || this.set_lang == undefined || this.set_lang == null) {
      translate.setDefaultLang('en');
    } else {
      translate.setDefaultLang(this.set_lang);
    }
  }

  public loadJsFile(url) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  public loadCSSFile(url) {
    let node = document.createElement('link');
    node.href = url;
    node.type = 'text/css';
    node.type = 'stylesheet';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  ngOnInit() {
    $("body").removeClass("diamond-search");
    $.noConflict();
    
  }

  ngAfterViewInit(){
    this.loadJsFile("../../assets/PSHNew/js/script.js");
  }
 


langtranslator(lan)
  {
    this.set_lang =lan;     sessionStorage.setItem('set_lang', lan);
    this.translate.use(lan);
    this.langEvent.next(lan);
  }

  redirectToLogin() {
    this.router.navigate(['/login'])
  }

  
  redirectToProduct() {
    this.router.navigate(['/products'])
  }

  redirectToQuotation(){
    this.router.navigate(['/quotation'])
  }

  redirectToIndex(){
    this.router.navigate(['/index'])
  }

  redirectToAboutUs(){
    this.router.navigate(['/aboutus'])
  }

  redirectToContactUs(){
    this.router.navigate(['/contact'])
  }

  redirectToTerms(){
    this.router.navigate(['/terms'])
  }
}
