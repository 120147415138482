import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { TabBarComponent } from './User/tab-bar/tab-bar.component';
import { IndexComponent } from './index/index.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

import {DashboardComponent} from "../app/User/dashboard/dashboard.component"
import { DiamondSearchComponent } from "../app/User/diamond-search/diamond-search.component";
import { DiamondResultComponent } from "../app/User/diamond-result/diamond-result.component";
import { DiamondDetailComponent } from "../app/User/diamond-detail/diamond-detail.component";
import { DiamondCartComponent } from "../app/User/diamond-cart/diamond-cart.component";
import { PendingOrderComponent } from "../app/User/pending-order/pending-order.component";
import { OrderSummaryComponent } from "../app/User/order-summary/order-summary.component";
import { OrderSummaryDetailComponent } from "../app/User/order-summary-detail/order-summary-detail.component";
import { DiamondDdcComponent } from "../app/User/diamond-ddc/diamond-ddc.component";
import { ChangePassowrdComponent } from '../app/AccountSetting/change-passowrd/change-passowrd.component'
import { EditProfileComponent } from '../app/AccountSetting/edit-profile/edit-profile.component';
import { SaveSearchViewComponent } from '../app/User/save-search-view/save-search-view.component';
import { StockDemandComponent } from '../app/User/stock-demand/stock-demand.component';
import { AboutusComponent } from '../app/aboutus/aboutus.component';
import { ContactusComponent } from '../app/contactus/contactus.component';
import { ProductsComponent } from '../app/products/products.component';
import { QuotationComponent } from '../app/quotation/quotation.component';
import { TermsComponent } from '../app/terms/terms.component';

const routes: Routes = [
  {path:'',component:IndexComponent},
  {path:'index',component:IndexComponent},
  {path:'login',component:LoginComponent},
  {path:'home',component:TabBarComponent},
  {path:'register',component:RegisterComponent},
  {path:'forgotpassword',component:ForgotPasswordComponent},
  {path:'dashbaord',component:DashboardComponent},
  {path:'search',component:DiamondSearchComponent},
  {path:'cart',component:DiamondCartComponent},
  {path:'pendingorder',component:PendingOrderComponent},
  {path:'orderSummary',component:OrderSummaryComponent},
  {path:'ddc',component:DiamondDdcComponent},
  {path:'changepassword',component:ChangePassowrdComponent},
  {path:'profile',component:EditProfileComponent},
  {path:'orderdetail',component:OrderSummaryDetailComponent},
  {path:'savesearch',component:SaveSearchViewComponent},
  {path:'demand',component:StockDemandComponent},
  {path:'aboutus',component:AboutusComponent},
  {path:'contact',component:ContactusComponent},
  {path:'products',component:ProductsComponent},
  {path:'quotation',component:QuotationComponent},
  {path:'terms',component:TermsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
