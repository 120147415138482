import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient) { }

  private BaseUrl = environment.BaseUrl;

  SocialMediaInsertByUser(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'register/SocialMediaInsertByUser',Data);
  }

  CountyFill(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'register/CountyFill',Data);
  }

  StateFill(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'register/StateFill',Data);
  }

  CityFill(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'register/CityFill',Data);
  }
  
  UserNameCheck(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'register/UserNameCheck',Data);
  }

  RegistrationSave(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'register/RegistrationSave',Data);
  }

 
  GetUserDetail():Observable<any>{
    return this.http.get<any>(this.BaseUrl + 'register/RegistrationDetailFill');
  }

  
  UpdateUserDetail(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'register/RegistrationUpdate',Data);
  }

  ContactUsSave(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'register/ContactUsSave',Data);
  }

  QuotationSave(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'register/QuotationSave',Data);
  }


}
