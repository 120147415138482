import { Component, OnInit } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import * as $ from "jquery";

import { DashboardService } from '../../Service/User/dashboard/dashboard.service';
import { TabBarService } from '../../Service/tab-bar.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import {TranslateService} from '@ngx-translate/core';
import { ICellRendererParams } from 'ag-grid-community';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  decodeHelper = new JwtHelperService();
  decodedTkn:any;
  PageCountArr = []
  SliderStone = []
  pageTitle = '';
  logoPath = '';
  public columnDefsWS;
  public gridApiWS;
  public gridColumnApiWS;
  public defaultColDefWS;

  public columnDefsPO;
  public gridApiPO;
  public gridColumnApiPO;
  public defaultColDefPO;
  set_lang:any='';
  constructor(
    private DashboardServ : DashboardService,
    private toastr: ToastrService,
    private TabBarServ : TabBarService,
    private translate: TranslateService,
  ) {

    translate.addLangs(['en', 'ch']);
    translate.setDefaultLang('en');
    this.set_lang=sessionStorage.getItem('set_lang');
    if(this.set_lang == '' || this.set_lang == undefined || this.set_lang == null)
    {
      translate.setDefaultLang('en');
    }else{
      translate.setDefaultLang(this.set_lang);
    }

    this.translate.onLangChange.subscribe(() => {
      this.gridApiWS.refreshHeader();
      this.gridApiPO.refreshHeader();
    })

    this.columnDefsWS = [
      {
        headerName: "StoneID",
        field: "StockNo",
        width: 114,
        headerClass: "text-center",
        cellStyle: function (params){
          if(params.data.DiamondStatus == 1){
             return { "text-align":"center" ,}
          }else{
            return { "text-align":"center" ,"background":'#FF99CC;'}
          }
        },
        cellRenderer: function (params) {
          if (params.data.DiamondStatus == 1) {
            return `<span style="color:#007bff;cursor:pointer" data-action-type="StoneDet">${params.data.StockNo}</span>`;
          } else {
            return `<span style="color:#007bff;cursor:pointer;background-color:#FF99CC;" data-action-type="StoneDet">${params.data.StockNo}</span>`;
          }
        },
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Cert",
        field: "Certi",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 64,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Loc",
        field: "Location",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 55,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Shape",
        field: "Shape",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 66,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Carat",
        field: "Carat",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 62,
        resizable: true,
        cellRenderer: function (params) {
          var html = "<span>" + params.data.Carat.toFixed(2) + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Color",
        field: "Color",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 63,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Clarity",
        field: "Clarity",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 66,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Cut",
        field: "Cut",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 49,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Pol",
        field: "Polish",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 49,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Sym",
        field: "Symm",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 53,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Flo",
        field: "Flo",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 50,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Flo",
        field: "Flo",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 50,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "CartROff",
        field: "Cart_Discount",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 100,
        resizable: true,
        cellRenderer: function (params) {
          var html = "<span>" + ((params.data.Discount)? params.data.Cart_Discount.toFixed(2) : 0.00) + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "CartPerCts",
        field: "Cart_PricePerCarat",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 100,
        resizable: true,
        cellRenderer: function (params) {
          var html = "<span>" + params.data.Cart_PricePerCarat.toFixed(2) + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "CartAmount",
        field: "Cart_Amount",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 100,
        resizable: true,
        cellRenderer: function (params) {
          var html = "<span>" + params.data.Discount.toFixed(2) + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "ROff",
        field: "Discount",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 68,
        resizable: true,
        cellRenderer: function (params) {
          // return html;
          if (params.data.Cart_Amount < params.data.Amount) {
            return "<span style='color:red;font-weight:bold' >" + ((params.data.Discount)? params.data.Discount.toFixed(2) : 0.00) + "</span>";
          } else if (params.data.Cart_Amount > params.data.Amount) {
            return "<span style='color:green;font-weight:bold' >" + ((params.data.Discount)? params.data.Discount.toFixed(2) : 0.00) + "</span>";
          } else {
            return "<span style='font-weight:bold'>" + ((params.data.Discount)? params.data.Discount.toFixed(2) : 0.00)+ "</span>";
          }
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
         headerName: "PerCrtRate",
        field: "PricePerCarat",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 70,
        resizable: true,
        cellRenderer: function (params) {
          // return html;
          if (params.data.Cart_Amount < params.data.Amount) {
            return "<span style='color:red;font-weight:bold' >" + params.data.PricePerCarat.toFixed(2) + "</span>";
          } else if (params.data.Cart_Amount > params.data.Amount) {
            return "<span style='color:green;font-weight:bold' >" + params.data.PricePerCarat.toFixed(2) + "</span>";
          } else {
            return "<span style='font-weight:bold'>" + params.data.PricePerCarat.toFixed(2) + "</span>";
          }
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Amount",
        field: "Amount",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 78,
        resizable: true,
        cellRenderer: function (params) {
          // return html;
          if (params.data.Cart_Amount < params.data.Amount) {
            return "<span style='color:red;font-weight:bold' >" + params.data.Amount.toFixed(2) + "</span>";
          } else if (params.data.Cart_Amount > params.data.Amount) {
            return "<span style='color:green;font-weight:bold' >" + params.data.Amount.toFixed(2) + "</span>";
          } else {
            return "<span style='font-weight:bold'>" + params.data.Amount.toFixed(2) + "</span>";
          }
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "ReportNo",
        field: "ReptNo",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 85,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "LU",
        field: "Luster",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 47,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Brn",
        field: "Brown",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 55,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
    ];
    this.columnDefsPO = [
      {
        headerName: "StoneID",
        field: "StockNo",
        width: 114,
        headerClass: "text-center",
        cellStyle: function (params){
          if(params.data.DiamondStatus == 1){
             return { "text-align":"center" ,}
          }else{
            return { "text-align":"center" ,"background":'#FF99CC;'}
          }
        },
        cellRenderer: function (params) {
          if (params.data.DiamondStatus == 1) {
            return `<span style="color:#007bff;cursor:pointer" data-action-type="StoneDet">${params.data.StockNo}</span>`;
          } else {
            return `<span style="color:#007bff;cursor:pointer;background-color:#FF99CC;" data-action-type="StoneDet">${params.data.StockNo}</span>`;
          }
        },
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Cert",
        field: "Certi",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 64,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Loc",
        field: "Location",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 55,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Shape",
        field: "Shape",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 66,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Carat",
        field: "Carat",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 62,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this),
        cellRenderer: function (params) {
          var html = "<span>" + params.data.Carat.toFixed(2) + "</span>";
          return html;
        }
      },
      {
        headerName: "Color",
        field: "Color",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 63,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Clarity",
        field: "Clarity",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 66,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Cut",
        field: "Cut",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 49,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Pol",
        field: "Polish",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 49,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Sym",
        field: "Symm",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 53,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Flo",
        field: "Flo",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 50,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Flo",
        field: "Flo",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 50,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "CartROff",
        field: "Cart_Discount",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 100,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this),
        cellRenderer: function (params) {
          var html = "<span>" +((params.data.Cart_Discount)? params.data.Cart_Discount.toFixed(2) : 0.00)  + "</span>";
          return html;
        }
      },
      {
        headerName: "CartPerCts",
        field: "Cart_PricePerCarat",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 100,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this),
        cellRenderer: function (params) {
          var html = "<span>" + ((params.data.Discount)? params.data.Discount.toFixed(2) : 0.00) + "</span>";
          return html;
        }
      },
      {
        headerName: "CartAmount",
        field: "Cart_Amount",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 100,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this),
        cellRenderer: function (params) {
          var html = "<span>" +((params.data.Cart_Amount)? params.data.Cart_Amount.toFixed(2) : 0.00)+ "</span>";
          return html;
        }
      },
      {
        headerName: "ROff",
        field: "Discount",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 68,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this),
        cellRenderer: function (params) {
          // return html;
          if (params.data.Cart_Amount < params.data.Amount) {
            return "<span style='color:red;font-weight:bold' >" +((params.data.Discount)? params.data.Discount.toFixed(2) : 0.00) + "</span>";
          } else if (params.data.Cart_Amount > params.data.Amount) {
            return "<span style='color:green;font-weight:bold' >" +((params.data.Discount)? params.data.Discount.toFixed(2) : 0.00)+ "</span>";
          } else {
            return "<span style='font-weight:bold'>" +((params.data.Discount)? params.data.Discount.toFixed(2) : 0.00) + "</span>";
          }
        }
      },
      {
         headerName: "PerCrtRate",
        field: "PricePerCarat",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 70,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this),
        cellRenderer: function (params) {
          // return html;
          if (params.data.Cart_Amount < params.data.Amount) {
            return "<span style='color:red;font-weight:bold' >" + params.data.PricePerCarat.toFixed(2) + "</span>";
          } else if (params.data.Cart_Amount > params.data.Amount) {
            return "<span style='color:green;font-weight:bold' >" + params.data.PricePerCarat.toFixed(2) + "</span>";
          } else {
            return "<span style='font-weight:bold'>" + params.data.PricePerCarat.toFixed(2) + "</span>";
          }
        }
      },
      {
        headerName: "Amount",
        field: "Amount",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 78,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this),
        cellRenderer: function (params) {
          // return html;
          if (params.data.Cart_Amount < params.data.Amount) {
            return "<span style='color:red;font-weight:bold' >" + params.data.Amount.toFixed(2) + "</span>";
          } else if (params.data.Cart_Amount > params.data.Amount) {
            return "<span style='color:green;font-weight:bold' >" + params.data.Amount.toFixed(2) + "</span>";
          } else {
            return "<span style='font-weight:bold'>" + params.data.Amount.toFixed(2) + "</span>";
          }
        }
      },
      {
        headerName: "ReportNo",
        field: "ReptNo",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 85,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "LU",
        field: "Luster",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 47,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Brn",
        field: "Brown",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 55,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
    ];
  }

  ngOnInit() {

    $("body").removeClass("diamond-search");

    this.FillPageAPI()
    this.TabBarServ.sharedPageName$.subscribe((sharedPageName) => {
      try {
        this.pageTitle = sharedPageName ? sharedPageName : 'Home';
      } catch (error) {
      }

    })

    setTimeout(() => {
      if(($('.sidebar-container').hasClass('sidebar-is-expanded')) == true){
        $('#imgLogo').attr("src","../../../assets/PSHNew/img/psh-dashbaord-big-logo.png");
        $("#imgLogo").addClass("largeLogo");
      }else{
        $('#imgLogo').attr("src","../../../assets/PSHNew/img/psh-dashbaord-small-logo.png");
        $("#imgLogo").removeClass("largeLogo");
      } 
    }, 700);

  }

  AddTab(Page: any) {
    this.pageTitle=Page;
    this.TabBarServ.setData(Page);
  }

  FillPageAPI(){
    this.decodedTkn = this.decodeHelper.decodeToken(localStorage.getItem('token'));

    this.DashboardServ.UserPanelDataCount({SessionId:this.decodedTkn.sessionId}).subscribe((CRes)=>{
      try{
        if(CRes.success == 1){
          this.PageCountArr = CRes.data[0]
        }else{
          this.toastr.warning("Something gone wrong while get page summary")
        }
      }catch(error){
        this.toastr.error(error)
      }
    })

    this.DashboardServ.SliderStoneFill({}).subscribe((SRes)=>{
      try {
        if(SRes.success == 1){
          this.SliderStone =  SRes.data
          this.SliderStone[0].Active = 'active';
        }else{
          this.toastr.warning("Something gone wrong while get slider stone")
        }
      } catch (error) {
        this.toastr.error(error)
      }
    })
  }

  onGridReadyWS(params) {
    this.gridApiWS = params.api;
    this.gridColumnApiWS = params.columnApi;

    this.DashboardServ.UserWiseCartList({}).subscribe((WCRes)=>{
      try{
        if(WCRes.success == 1){
          this.gridApiWS.setRowData(WCRes.data);
        }else{
          this.toastr.warning("Something gone wrong while get cart data")
        }
      }catch(error){
        this.toastr.error(error)
      }
    })
  }

  onGridReadyPO(params) {
    this.gridApiPO = params.api;
    this.gridColumnApiPO = params.columnApi;

    this.DashboardServ.OrderlistofPendingByUser({}).subscribe((PRes)=>{
      try{
        if(PRes.success == 1){
          this.gridApiPO.setRowData(PRes.data);
        }else{
          this.toastr.warning("Something gone wrong while get pending data")
        }
      }catch(error){
        this.toastr.error(error)
      }
    })
  }


  onGridRowClickedWS(eve: any) {
    if (eve.event.target !== undefined) {
      let actionType = eve.event.target.getAttribute("data-action-type");
      if (actionType == "StoneDet") {
        localStorage.removeItem("SD");
        localStorage.setItem("SD", eve.data.StockNo);
        localStorage.setItem(eve.data.StockNo,JSON.stringify(eve.data))
        this.TabBarServ.setData("Stone Detail (" + eve.data.StockNo + ")");
      }
    }
  }

  onGridRowClickedPO(eve: any) {
    if (eve.event.target !== undefined) {
      let actionType = eve.event.target.getAttribute("data-action-type");
      if (actionType == "StoneDet") {
        localStorage.removeItem("SD");
        localStorage.setItem("SD", eve.data.StockNo);
        localStorage.setItem(eve.data.StockNo,JSON.stringify(eve.data))
        this.TabBarServ.setData("Stone Detail (" + eve.data.StockNo + ")");
      }
    }
  }

  langtranslator(lan)
  {
    sessionStorage.setItem('set_lang',lan);
    this.set_lang=sessionStorage.getItem('set_lang');
    this.translate.use(lan);
  }

  public localizeHeader(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.headerName;
    return this.translate.instant(headerIdentifier);
  }
}
