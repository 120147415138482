import { Component, OnInit,Output, EventEmitter } from '@angular/core';
import { Router , NavigationStart } from "@angular/router";
import { TabBarService } from  '../../Service/tab-bar.service'
import * as $ from "jquery";
import { JwtHelperService } from '@auth0/angular-jwt';
import {TranslateService} from '@ngx-translate/core';
import { filter } from 'rxjs/operators'
import { Subscriber } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';



@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {

  @Output() langEvent = new EventEmitter<string>();
  pageTitle: any = "";
  set_lang:any='';
  logoPath:any='';
  Certi_list:any='';
  decodeHelper = new JwtHelperService();
  decodedTkn = this.decodeHelper.decodeToken(localStorage.getItem('token'));

  dashbaord_active:boolean=false
  search_active:boolean=false
  cart_active:boolean=false
  pendingorder_active:boolean=false
  orderSummary_active:boolean=false
  ddc_active:boolean=false
  profile_active:boolean=false
  changepassword_active:boolean=false
  save_search:boolean=false
  stock_demand:boolean=false


  constructor(
    private router: Router,
    private TabBarServ: TabBarService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer
    ) {
    this.pageTitle = "Home";
    translate.addLangs(['en', 'ch']);
    translate.setDefaultLang('en');
    this.set_lang=sessionStorage.getItem('set_lang');
    if(this.set_lang == '' || this.set_lang == undefined || this.set_lang == null)
    {
      this.set_lang='en';
      translate.setDefaultLang('en');
    }else{
      translate.setDefaultLang(this.set_lang);
    }

    router.events.subscribe(val => {
      this.dashbaord_active=false
      this.search_active=false
      this.cart_active=false
      this.pendingorder_active=false
      this.orderSummary_active=false
      this.ddc_active=false
      this.profile_active=false
      this.changepassword_active=false
      this.save_search=false
      this.stock_demand=false

      if (window.location.href.indexOf('/dashbaord')>=0) {
        this.dashbaord_active=true
      }
      if (window.location.href.indexOf('/search')>=0) {
        this.search_active = true
      }
      if (window.location.href.indexOf('/cart')>=0) {
        this.cart_active = true
      }
      if (window.location.href.indexOf('/pendingorder')>=0) {
        this.pendingorder_active = true
      }
      if (window.location.href.indexOf('/orderSummary')>=0) {
        this.orderSummary_active =true
      }
      if (window.location.href.indexOf('/ddc')>=0) {
        this.ddc_active=true
      }
      if (window.location.href.indexOf('/profile')>=0) {
        this.profile_active=true
      }
      if (window.location.href.indexOf('/changepassword')>=0) {
        this.changepassword_active=true
      }
      if (window.location.href.indexOf('/savesearch')>=0) {
        this.save_search=true
      }
      if (window.location.href.indexOf('/demand')>=0) {
        this.stock_demand=true
      }
   });
  }

  ngOnInit(): void {

    sessionStorage.setItem('set_lang',this.decodedTkn.Default_Lang.toString())
    this.set_lang=sessionStorage.getItem('set_lang');

    $(".main-container").addClass("sidebar-is-expanded");
    $(".sidebar-container").addClass("sidebar-is-expanded");
    $(".sub-main").addClass("sidebar-is-expanded");
    $('#pp-nav').removeAttr('id');
    $(".has-submenu").click(function(){
      $(this).toggleClass(
        "is-active"
      );
      $(this).parent().toggleClass(
        "is-active"
      );
    });
    var Dashboard = (function() {
      var global = {
        tooltipOptions: {
          placement: "right"
        },
        menuClass: ".c-menu"
      };

       
      
      var sidebarChangeWidth = function sidebarChangeWidth() {
        var $menuItemsTitle = $("li .menu-item__title");

        $(".main-container").toggleClass(
          "sidebar-is-reduced sidebar-is-expanded"
        );
        $(".sidebar-container").toggleClass(
          "sidebar-is-reduced sidebar-is-expanded"
        );
        $(".sub-main").toggleClass(
          "sidebar-is-reduced sidebar-is-expanded"
        );
        $(".hamburger-toggle").toggleClass("is-opened");
        
        setTimeout(() => {
          if(($('.sidebar-container').hasClass('sidebar-is-expanded')) == true){
            $('#imgLogo').attr("src","../../../assets/PSHNew/img/psh-dashbaord-big-logo.png");
            $("#imgLogo").addClass("largeLogo");
          }else{
            $('#imgLogo').attr("src","../../../assets/PSHNew/img/psh-dashbaord-small-logo.png");
            $("#imgLogo").removeClass("largeLogo");
          } 
          // this.logoPath = this.sanitizer.bypassSecurityTrustResourceUrl(this.logoPath);
        }, 700);
      };

      return {
        init: function init() {
          $(".js-hamburger").on("click", sidebarChangeWidth);
        }
      };
    })();
 
 
    Dashboard.init();

    // this.TabBarServ.sharedPageName$.subscribe((sharedPageName) => {
    //   try {
    //     this.pageTitle = sharedPageName ? sharedPageName : 'Home';
    //   } catch (error) {
    //   }

    // })

  }

  Logout() {
   // localStorage.clear();
   localStorage.removeItem("token");
   localStorage.removeItem("sctn");
    this.router.navigateByUrl("/login");
  }

  AddTab(Page: any) {
    this.pageTitle=Page;

    if(this.pageTitle == "Dashboard"){
      this.router.navigateByUrl("/dashbaord");
    }else if(this.pageTitle == "Diamond Search"){
      this.router.navigateByUrl("/search");
    }else if(this.pageTitle == "Basket"){
      this.router.navigateByUrl("/cart");
    }else if(this.pageTitle == "Pending Order"){
      this.router.navigateByUrl("/pendingorder");
    }else if(this.pageTitle == "Confirmation Summary"){
      this.router.navigateByUrl("/orderSummary");
    }else if(this.pageTitle == "DDC"){
      this.router.navigateByUrl("/ddc");
    }else if(this.pageTitle == "Change Password"){
      this.router.navigateByUrl("/changepassword");
    }else if(this.pageTitle == "Edit Profile"){
      this.router.navigateByUrl("/profile");
    }else if(this.pageTitle == 'Save Search'){
      this.router.navigateByUrl('/savesearch')
    }else if(this.pageTitle == 'Stock Demand'){
      this.router.navigateByUrl('/demand')
    }
   // this.TabBarServ.setData(Page);
  }


  langtranslator(lan)
  {
    this.set_lang =lan;
    sessionStorage.setItem('set_lang', lan);
    this.translate.use(lan);
    this.langEvent.next(lan);
  }

  Search_cert_wise(){
    let CertObj= {
      certificate_list:this.Certi_list ? this.Certi_list.split(/[ ,]+/).toString():'',
      DiamondType:0
    }
    localStorage.setItem("SearchPara",JSON.stringify(CertObj).toString())
    let ctn = localStorage.getItem('sctn')
    ctn  =( parseInt(ctn) + 1).toString();
    localStorage.setItem("sctn",ctn)
    this.TabBarServ.setData("Diamond Result (" + ctn + ")");
  }

}
