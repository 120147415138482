import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

  private BaseUrl = environment.BaseUrl;

  OrderSummaryFill(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'Order/OrderSummaryFill',Data);
  }

  OrderDetailFill(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'Order/GetInvoiceDetail',Data);
  }

  OrderSave(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'Order/OrderSave',Data);
  }
}
