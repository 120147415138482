import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { DomSanitizer } from '@angular/platform-browser';
import {  DiamondSearchService } from '../../Service/User/diamond-search/diamond-search.service';
import { TabBarService } from '../../Service/tab-bar.service';
import {TranslateService} from '@ngx-translate/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ImgVideoComponent } from "../img-video/img-video.component";
import { environment } from '../../../environments/environment';
import { ICellRendererParams } from 'ag-grid-community';
import { OrderService } from "../../Service/User/diamond-order/order.service";
import { DiamondDetailComponent } from '../diamond-detail/diamond-detail.component';
import * as $ from "jquery";
import { SaveSearchDemandModalComponent } from './save-search-demand-modal/save-search-demand-modal.component';

@Component({
  selector: "app-diamond-search",
  templateUrl: "./diamond-search.component.html",
  styleUrls: ["./diamond-search.component.css"]
})
export class DiamondSearchComponent implements OnInit {
  decodeHelper = new JwtHelperService();
  decodedTkn = this.decodeHelper.decodeToken(localStorage.getItem('token'));

  set_lang:any='';
  StockView: FormGroup;

  ex3 = false;
  vg3 = false;
  gdofr = false;
  whiteColor = true;
  exminus = false;
  vgthreeminus = false;

  panelOpenState = false;

  locationList: any[] = [];
  fancyintensityList: any[] = [];
  fancyovertoneList: any[] = [];
  fancycolorList: any[] = [];
  shadeList: any[] = [];
  isSearchNoActive =false;

  selectedRow = [];
  totalCts: any = 0.0;
  avgRap: any = 0.0;
  avgDisc: any = 0.0;
  ctRate: any = 0.0;
  totAmount: any = 0.0;

  public columnDefs;
  public columnDefsCh;
  public gridApi;
  public gridColumnApi;
  public defaultColDef;
  public rowSelection;
  public isRowSelectable;

  SearchCountDigit:any = '0'

  apiUrl = environment.BaseUrl;

  initObj = {
    shape: {
      selectAll: false,
      selectCount: 0,
      items: [
        {
          name: "Round",
          code: 3,
          selected: true,
          className: "icon-Round1"
        },
        {
          name: "Emerald",
          code: 7,
          selected: false,
          className: "icon-Emerald3"
        },
        {
          name: "Cushion",
          code: 6,
          selected: false,
          className: "icon-Cushion1"
        },
        {
          name: "Princess",
          code: 5,
          selected: false,
          className: "icon-ico_Princess"
        },
        {
          name: "Marquise",
          code: 11,
          selected: false,
          className: "icon-Marquise1"
        },
        {
          name: "Pear",
          code: 14,
          selected: false,
          className: "icon-Pear1"
        },
        {
          name: "Heart",
          code: 9,
          selected: false,
          className: "icon-Heart3"
        },
        {
          name: "Oval",
          code: 12,
          selected: false,
          className: "icon-Oval3"
        },
        {
          name: "SqEmerald",
          code: 8,
          selected: false,
          className: "icon-Square-Emerald1"
        },
        {
          name: "Radient",
          code: 16,
          selected: false,
          className: "icon-LgRadiant3"
        },
        {
          name: "Other Shapes",
          code: 1,
          selected: false,
          className: "icon-other"
        }
      ],
    },
    carat:{
      selectCount: 0,
      items: [
        { SizeRange: "0.01-0.14", selected: false , code: 1 },
        { SizeRange: "0.15-0.29", selected: false , code: 2 },
        { SizeRange: "0.30-0.39", selected: false , code: 3 },
        { SizeRange: "0.40-0.49", selected: false , code: 4 },
        { SizeRange: "0.50-0.69", selected: false , code: 5 },
        { SizeRange: "0.70-0.89", selected: false , code: 6 },
        { SizeRange: "0.90-0.99", selected: false , code: 7 },
        { SizeRange: "1.00-1.49", selected: false , code: 8 },
        { SizeRange: "1.50-1.99", selected: false , code: 9 },
        { SizeRange: "2.00-2.99", selected: false , code: 10 },
        { SizeRange: "3.00-4.99", selected: false , code: 11 },
        { SizeRange: "5.00-5.99", selected: false , code: 12 }
      ]
    },
    color: {
      selectAll: false,
      selectCount: 0,
      items: [
        { name: "D", code: 1, selected: false },
        { name: "E", code: 2, selected: false },
        { name: "F", code: 3, selected: false },
        { name: "G", code: 4, selected: false },
        { name: "H", code: 5, selected: false },
        { name: "I", code: 6, selected: false },
        { name: "J", code: 7, selected: false },
        { name: "K", code: 8, selected: false },
        { name: "L", code: 9, selected: false },
        { name: "M", code: 10, selected: false },
        { name: "N", code: 11, selected: false },
        { name: "O-P", code: 96, selected: false },
          { name: "Q-R", code: 98, selected: false },
          { name: "S-T", code: 99, selected: false },
          { name: "U-V", code: 100, selected: false },
          { name: "W-X", code: 101, selected: false },
          { name: "Y-Z", code: 102, selected: false }
      ],
    },
    clarity: {
      selectAll: false,
      items: [
        { name: "FL", code: 1, selected: false },
        { name: "IF", code: 3, selected: false },
        { name: "VVS1", code: 4, selected: false },
        { name: "VVS2", code: 5, selected: false },
        { name: "VS1 ", code: 6, selected: false },
        { name: "VS2", code: 7, selected: false },
        { name: "SI1", code: 8, selected: false },
        { name: "SI2", code: 9, selected: false },
        { name: "SI3", code: 10, selected: false },
        { name: "I1", code: 11, selected: false },
        { name: "I2", code: 15, selected: false },
        { name: "I3", code: 13, selected: false }
      ],
      selectCount: 0
    },
    cut: {
      selectAll: false,
      items: [
        { name: "EX", code: 1, selected: false },
        { name: "VG", code: 2, selected: false },
        { name: "GD", code: 3, selected: false },
        { name: "FR", code: 4, selected: false }
      ],
      selectCount: 0
    },
    pol: {
      selectAll: false,
      items: [
        { name: "EX", code: 1, selected: false },
        { name: "VG", code: 2, selected: false },
        { name: "GD", code: 4, selected: false },
        { name: "FR", code: 5, selected: false }
      ],
      selectCount: 0
    },
    symm: {
      selectAll: false,
      items: [
        { name: "EX", code: 1, selected: false },
        { name: "VG", code: 2, selected: false },
        { name: "GD", code: 3, selected: false },
        { name: "FR", code: 4, selected: false }
      ],
      selectCount: 0
    },
    fluorescence: {
      selectAll: false,
      items: [
        { name: "NONE", code: 1, selected: false },
        { name: "FAINT", code: 4, selected: false },
        { name: "MEDIUM", code: 2, selected: false },
        { name: "STRONG", code: 3, selected: false },
        { name: "VERY STRONG", code: 5, selected: false }
      ],
      selectCount: 0
    },
    eye: {
      selectAll: false,
      items: [
        { name: "Yes", code: 3, selected: false },
        { name: "No", code: 2, selected: false },
      ],
      selectCount: 0
    },
    luster: {
      selectAll: false,
      items: [
        { name: "EX", code: 1, selected: false },
        { name: "VG", code: 2, selected: false },
        { name: "ML1", code: 3, selected: false },
        { name: "ML2", code: 4, selected: false },
        { name: "ML3", code: 5, selected: false },
        { name: "G", code: 6, selected: false },
      ],
      selectCount: 0
    },
    ha: {
      selectAll: false,
      items: [
        { name: "EX", code: 1, selected: false },
        { name: "VG", code: 2, selected: false }
      ],
      selectCount: 0
    },
    lab: {
      selectAll: false,
      items: [
        { name: "GIA", code: 3, selected: false },
        { name: "HRD", code: 2, selected: false },
        { name: "IGI", code: 4, selected: false },
        { name: "SGS", code: 5, selected: false },
        { name: "AGS", code: 6, selected: false },
        { name: "EGL", code: 7, selected: false },
        { name: "IGC", code: 8, selected: false },
        { name: "IIDGR", code: 9, selected: false },
        { name: "NGTC", code: 10, selected: false },
        { name: "FM", code: 12, selected: false },
        { name: "GSI", code: 13, selected: false }
      ],
      selectCount: 0
    },
  };

  constructor(
    private domSanitizer : DomSanitizer,
    public fb: FormBuilder,
    public DiamondSearchServ :DiamondSearchService,
    private spinner: NgxSpinnerService,
    private matDialog: MatDialog,
    private TabBarServ: TabBarService,
    private toastr : ToastrService,
    private translate: TranslateService,
    private orderService:OrderService
  ) {

    translate.addLangs(['en', 'ch']);
    translate.setDefaultLang('en');
    this.set_lang=sessionStorage.getItem('set_lang');
    if(this.set_lang == '' || this.set_lang == undefined || this.set_lang == null)
    {
      translate.setDefaultLang('en');
    }else{
      translate.setDefaultLang(this.set_lang);
    }


    this.StockView = this.fb.group({
      locationControl: [[]],

      fancyintensityControl: [[]],
      fancyovertoneControl: [[]],
      fancycolorControl: [[]],
      shadeControl:[[]],
      from_carat:null,
      to_carat:null,
      from_diamin:null,
      to_diamin:null,
      from_table:null,
      to_table:null,
      from_pavheight:null,
      to_pavheight:null,
      from_diamax:null,
      to_diamax:null,
      from_diaheight:null,
      to_diaheight:null,
      from_totaldepth:null,
      to_totaldepth:null,
      from_crownangle:null,
      to_crownangle:null,
      from_crownheight:null,
      to_crownheight:null,
      from_pavangle:null,
      to_pavangle:null,
      from_gridle:null,
      to_gridle:null,
      from_price:null,
      to_price:null,
      from_per:null,
      to_per:null,
      stoneid_list:null,
      certificate_list:null
    })

    this.translate.onLangChange.subscribe(() => {
      this.gridApi.refreshHeader();
    })

    this.columnDefs = [
      {
        width: 28,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        resizable: false,
        filter: false,
        sortable: false
      },
      {
        headerName: "StoneID",
        field: "StockNo",
        width: 114,
        headerClass: "text-left",
        cellStyle: function (params){
          if(params.data.DiamondStatus == 1){
             return { "text-align":"center" ,}
          }else{
            return { "text-align":"center" ,"background":'#FF99CC;'}
          }
        },
        cellRenderer: function(params) {
          if (params.data.DiamondStatus == 1) {
            return `<span style="color:#007bff;cursor:pointer" data-action-type="StoneDet">${params.data.StockNo}</span>`;
          } else {
            return `<span style="color:#007bff;cursor:pointer;background-color:#FF99CC;" data-action-type="StoneDet">${params.data.StockNo}</span>`;
          }
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Details",
        headerClass: "text-left",
        width: 85,
        resizable: true,
        filter: false,
        sortable: false,
        cellRenderer: function(params) {
          var htmlStr =  '';
          
          if (params.data.ImageUrl != "") {
            htmlStr = htmlStr +'<i class="icon-camera" data-action-type="photo" ></i>&nbsp;';
          } else{
            htmlStr = htmlStr +'<i class="icon-camera" data-action-type="photo" style="visibility: hidden;" ></i>&nbsp;';
          }
          if (params.data.VideoUrl != "") {
            htmlStr = htmlStr +'<i class="icon-ico_Movie" data-action-type="video"></i>&nbsp;';
          } else{
            htmlStr = htmlStr +'<i class="icon-ico_Movie" data-action-type="video" style="visibility: hidden;" ></i>&nbsp;';
          }
          if (params.data.CertPDFUrl != "") {
            htmlStr = htmlStr +'<i class="icon-certificate" data-action-type="certificat"></i>';
          } else{
            htmlStr = htmlStr +'<i class="icon-certificate" data-action-type="certificat" style="visibility: hidden;"></i>';
          }
          var html =
            '<span class="det_val">'+htmlStr+'</span>';
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      }, 
      {
        headerName: "Lab",
        field: "Certi",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 64,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Loc",
        field: "Location",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 55,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Shape",
        field: "Shape",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 66,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Carat",
        field: "Carat",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 62,
        resizable: true,
        cellRenderer: function(params) {
          var html = "<span>" + params.data.Carat.toFixed(2) + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Color",
        field: "Color",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: this.whiteColor ? 63:100,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Clarity",
        field: "Clarity",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 66,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Cut",
        field: "Cut",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 49,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Pol",
        field: "Polish",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 49,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Sym",
        field: "Symm",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 53,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Flo",
        field: "Flo",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 50,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "ORap",
        field: "RapRate",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 64,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "ROff",
        field: "Discount",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 68,
        resizable: true,
        cellRenderer: function(params) {
          var html = "<span>" + ((params.data.Discount)? params.data.Discount.toFixed(2) : 0.00)  + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "PerCrtRate",
        field: "PricePerCarat",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 70,
        resizable: true,
        cellRenderer: function(params) {
          var html =
            "<span>" + params.data.PricePerCarat.toFixed(2) + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Amount",
        field: "Amount",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 78,
        resizable: true,
        cellRenderer: function(params) {
          var html = "<span>" + params.data.Amount.toFixed(2) + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "ReportNo",
        field: "ReptNo",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 85,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Measurement",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 110,
        resizable: true,
        cellRenderer: function(params) {
          var html = `<span>${params.data.DMin}-${params.data.DMax}*${params.data.DHeight}</span>`;
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Table",
        field: "Tbl",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 58,
        resizable: true,
        cellRenderer: function(params) {
          var html = "<span>" + params.data.Tbl + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Depth",
        field: "TableDepth",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 66,
        resizable: true,
        cellRenderer: function(params) {
          var html = "<span>" + params.data.TableDepth + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "LU",
        field: "Luster",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 47,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Brn",
        field: "Brown",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 55,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "TB",
        field: "TblBlack",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 47,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "SB",
        field: "SideBlack",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 48,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "TI",
        field: "TableIncluction",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 64,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Culet",
        field: "Culet",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 60,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "GirPer",
        field: "GirdlePer",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 59,
        resizable: true,
        cellRenderer: function(params) {
          var html = "<span>" + params.data.GirdlePer + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "HA",
        field: "HNA",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 60,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      }
    ];



    this.defaultColDef = {
      resizable: true,
      filter: false,
      sortable: true
    };

    this.rowSelection = "multiple";
  }

  ngOnInit() {
    var bodyTag = document.createElement('body');
    bodyTag.style.overflow = 'auto';

    $("body").addClass("diamond-search");

    setTimeout(() => {
      if(($('.sidebar-container').hasClass('sidebar-is-expanded')) == true){
        $('#imgLogo').attr("src","../../../assets/PSHNew/img/psh-dashbaord-big-logo.png");
        $("#imgLogo").addClass("largeLogo");
      }else{
        $('#imgLogo').attr("src","../../../assets/PSHNew/img/psh-dashbaord-small-logo.png");
        $("#imgLogo").removeClass("largeLogo");
      } 
    }, 700);

    this.DiamondSearchServ.SearchMasterFill({}).subscribe(MastRes => {
      try {
        if (MastRes.success == 1) {

          let tempARy = MastRes.data[3].map(Inc => {
            return { name: Inc.Intensity, code: 1 };
          });

          tempARy.unshift({ name: "All", code: 0 });
          this.fancyintensityList = tempARy;

          if (history.state.defaultSearchData && history.state.defaultSearchData.FANCYINTENSITY) {
            let defaultFilter = this.fancyintensityList.filter(item => history.state.defaultSearchData.FANCYINTENSITY.split(',').includes(item.name))
            this.StockView.patchValue({fancyintensityControl: defaultFilter});
          }

          let tempARyOv = MastRes.data[4].map(Ove => {
            return { name: Ove.Overton, code: 1 };
          });

          tempARyOv.unshift({ name: "All", code: 0 });
          this.fancyovertoneList = tempARyOv;

          if (history.state.defaultSearchData && history.state.defaultSearchData.FANCYOVERTONE) {
            let defaultFilter = this.fancyovertoneList.filter(item => history.state.defaultSearchData.FANCYOVERTONE.split(',').includes(item.name))
            this.StockView.patchValue({fancyovertoneControl: defaultFilter});
          }

          let tempARyCol = MastRes.data[2].map(Col => {
            return { name: Col.FancyColour, code: 1 };
          });

          tempARyCol.unshift({ name: "All", code: 0 });
          this.fancycolorList = tempARyCol;

          if (history.state.defaultSearchData && history.state.defaultSearchData.FANCYCOLOR) {
            let defaultFilter = this.fancycolorList.filter(item => history.state.defaultSearchData.FANCYCOLOR.split(',').includes(item.name))
            this.StockView.patchValue({fancycolorControl: defaultFilter});
          }


          let tempARyShd = MastRes.data[0].map(Shd => {
            return { name: Shd.Name, code: parseInt(Shd.id) };
          });

          tempARyShd.unshift({ name: "All", code: 0 });
          this.shadeList = tempARyShd;

          if (history.state.defaultSearchData && history.state.defaultSearchData.SHADE_LIST) {
            let defaultFilter = this.shadeList.filter(item => history.state.defaultSearchData.SHADE_LIST.split(',').includes((item.code).toString()))
            this.StockView.patchValue({shadeControl: defaultFilter});
          }



          let tempARyLoc = MastRes.data[1].map(Loc => {
            return { name: Loc.Name, code: parseInt(Loc.id) };
          });

          tempARyLoc.unshift({ name: "All", code: 0 });
          this.locationList = tempARyLoc;

          if (history.state.defaultSearchData && history.state.defaultSearchData.LOCATION_LIST) {
            let defaultFilter = this.locationList.filter(item => history.state.defaultSearchData.LOCATION_LIST.split(',').includes((item.code).toString()))
            this.StockView.patchValue({locationControl: defaultFilter});
          }


        } else {
        }
        this.SearchCount();
      } catch (error) {
        console.log(error);
      }
    });

    if (history.state.defaultSearchData && history.state.defaultSearchData.SHAPE_LIST) {
      this.initObj.shape.items = this.initObj.shape.items.map((item) => {
        return {
          name: item.name,
          code: item.code,
          selected: history.state.defaultSearchData.SHAPE_LIST.split(',').includes((item.code).toString()),
          className: item.className
        }
      })
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.FROM_CARAT) {
      this.StockView.patchValue({from_carat: history.state.defaultSearchData.FROM_CARAT})
    }
    if (history.state.defaultSearchData && history.state.defaultSearchData.TO_CARAT) {
      this.StockView.patchValue({to_carat: history.state.defaultSearchData.TO_CARAT})
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.SIZE_LIST) {
      this.initObj.carat.items = this.initObj.carat.items.map((item) => {
        return {
          SizeRange: item.SizeRange,
          code: item.code,
          selected: history.state.defaultSearchData.SIZE_LIST.split(',').map(item => parseInt(item)).includes(item.code)
        }
      })
    }



    if (history.state.defaultSearchData && history.state.defaultSearchData.COLOR_LIST) {

      this.initObj.color.items = this.initObj.color.items.map((item) => {
        return {
          name: item.name,
          code: item.code,
          selected: history.state.defaultSearchData.COLOR_LIST.split(',').map(item => parseInt(item)).includes(item.code)
        }
      })
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.CLARITY_LIST) {
      this.initObj.clarity.items = this.initObj.clarity.items.map((item) => {
        return {
          name: item.name,
          code: item.code,
          selected: history.state.defaultSearchData.CLARITY_LIST.split(',').map(item => parseInt(item)).includes(item.code)
        }
      })
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.CUT_LIST) {
      this.initObj.cut.items = this.initObj.cut.items.map((item) => {
        return {
          name: item.name,
          code: item.code,
          selected: history.state.defaultSearchData.CUT_LIST.split(',').map(item => parseInt(item)).includes(item.code)
        }
      })
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.FLO_LIST) {
      this.initObj.fluorescence.items = this.initObj.fluorescence.items.map((item) => {
        return {
          name: item.name,
          code: item.code,
          selected: history.state.defaultSearchData.FLO_LIST.split(',').map(item => parseInt(item)).includes(item.code)
        }
      })
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.POLISH_LIST) {
      this.initObj.pol.items = this.initObj.pol.items.map((item) => {
        return {
          name: item.name,
          code: item.code,
          selected: history.state.defaultSearchData.POLISH_LIST.split(',').map(item => parseInt(item)).includes(item.code)
        }
      })
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.CERT_LIST) {
      this.initObj.lab.items = this.initObj.lab.items.map((item) => {
        return {
          name: item.name,
          code: item.code,
          selected: history.state.defaultSearchData.CERT_LIST.split(',').map(item => parseInt(item)).includes(item.code)
        }
      })
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.SYMM_LIST) {
      this.initObj.symm.items = this.initObj.symm.items.map((item) => {
        return {
          name: item.name,
          code: item.code,
          selected: history.state.defaultSearchData.SYMM_LIST.split(',').map(item => parseInt(item)).includes(item.code)
        }
      })
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.LUSTER_LIST) {
      this.initObj.luster.items = this.initObj.luster.items.map((item) => {
        return {
          name: item.name,
          code: item.code,
          selected: history.state.defaultSearchData.LUSTER_LIST.split(',').map(item => parseInt(item)).includes(item.code)
        }
      })
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.EYECLEAN_LIST) {
      this.initObj.eye.items = this.initObj.eye.items.map((item) => {
        return {
          name: item.name,
          code: item.code,
          selected: history.state.defaultSearchData.EYECLEAN_LIST.split(',').map(item => parseInt(item)).includes(item.code)
        }
      })
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.HA_LIST) {
      this.initObj.ha.items = this.initObj.ha.items.map((item) => {
        return {
          name: item.name,
          code: item.code,
          selected: history.state.defaultSearchData.HA_LIST.split(',').map(item => parseInt(item)).includes(item.code)
        }
      })
    }


    if (history.state.defaultSearchData && history.state.defaultSearchData.FROM_TABLE) {
      this.StockView.patchValue({from_table: history.state.defaultSearchData.FROM_TABLE})
    }
    if (history.state.defaultSearchData && history.state.defaultSearchData.TO_TABLE) {
      this.StockView.patchValue({to_table: history.state.defaultSearchData.TO_TABLE})
    }



    if (history.state.defaultSearchData && history.state.defaultSearchData.FROM_PAVANGLE) {
      this.StockView.patchValue({from_pavangle: history.state.defaultSearchData.FROM_PAVANGLE})
    }
    if (history.state.defaultSearchData && history.state.defaultSearchData.TO_PAVANGLE) {
      this.StockView.patchValue({to_pavangle: history.state.defaultSearchData.TO_PAVANGLE})
    }


    if (history.state.defaultSearchData && history.state.defaultSearchData.FROM_PAVHEIGHT) {
      this.StockView.patchValue({from_pavheight: history.state.defaultSearchData.FROM_PAVHEIGHT})
    }
    if (history.state.defaultSearchData && history.state.defaultSearchData.TO_PAVHEIGHT) {
      this.StockView.patchValue({to_pavheight: history.state.defaultSearchData.TO_PAVHEIGHT})
    }


    if (history.state.defaultSearchData && history.state.defaultSearchData.FROM_CROWNHEIGHT) {
      this.StockView.patchValue({from_crownheight: history.state.defaultSearchData.FROM_CROWNHEIGHT})
    }
    if (history.state.defaultSearchData && history.state.defaultSearchData.TO_CROWNHEIGHT) {
      this.StockView.patchValue({to_crownheight: history.state.defaultSearchData.TO_CROWNHEIGHT})
    }


    if (history.state.defaultSearchData && history.state.defaultSearchData.FROM_CROWNANGLE) {
      this.StockView.patchValue({from_crownangle: history.state.defaultSearchData.FROM_CROWNANGLE})
    }
    if (history.state.defaultSearchData && history.state.defaultSearchData.TO_CROWNANGLE) {
      this.StockView.patchValue({to_crownangle: history.state.defaultSearchData.TO_CROWNANGLE})
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.FROM_TOTALDEPTH) {
      this.StockView.patchValue({from_totaldepth: history.state.defaultSearchData.FROM_TOTALDEPTH})
    }
    if (history.state.defaultSearchData && history.state.defaultSearchData.TO_TOTALDEPTH) {
      this.StockView.patchValue({to_totaldepth: history.state.defaultSearchData.TO_TOTALDEPTH})
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.FROM_CARAT) {
      this.StockView.patchValue({from_carat: history.state.defaultSearchData.FROM_CARAT})
    }
    if (history.state.defaultSearchData && history.state.defaultSearchData.TO_CARAT) {
      this.StockView.patchValue({to_carat: history.state.defaultSearchData.TO_CARAT})
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.FROM_PER) {
      this.StockView.patchValue({from_per: history.state.defaultSearchData.FROM_PER})
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.TO_PER) {
      this.StockView.patchValue({to_per: history.state.defaultSearchData.TO_PER})
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.FROM_PRICE) {
      this.StockView.patchValue({from_price: history.state.defaultSearchData.FROM_PRICE})
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.TO_PRICE) {
      this.StockView.patchValue({to_price: history.state.defaultSearchData.TO_PRICE})
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.FROM_DIAMIN) {
      this.StockView.patchValue({from_diamin: history.state.defaultSearchData.FROM_DIAMIN})
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.TO_DIAMIN) {
      this.StockView.patchValue({to_diamin: history.state.defaultSearchData.TO_DIAMIN})
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.FROM_DIAMAX) {
      this.StockView.patchValue({from_diamax: history.state.defaultSearchData.FROM_DIAMAX})
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.TO_DIAMAX) {
      this.StockView.patchValue({to_diamax: history.state.defaultSearchData.TO_DIAMAX})
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.FROM_DIAMAX) {
      this.StockView.patchValue({from_diaheight: history.state.defaultSearchData.FROM_DIAMAX})
    }

    if (history.state.defaultSearchData && history.state.defaultSearchData.FROM_DIAHEIGHT) {
      this.StockView.patchValue({to_diaheight: history.state.defaultSearchData.TO_DIAHEIGHT})
    }


    this.whiteColor = true;

    if (history.state.defaultSearchData && history.state.defaultSearchData.DIAMONDTYPE) {
      if(history.state.defaultSearchData.DIAMONDTYPE == 0){
        this.whiteColor = true; 
      }else{
        this.whiteColor = false; 
      }
      
    }

    if(!history.state.defaultSearchData){
      setTimeout(()=>{
       // this.doCalculation();
      },1500)
    }
 

  }

  checkAll(bool, tarGet) {
    if (tarGet == "luster1") {
      // this.lustList = this.lustList.map(row => {
      //   return { name: row.name, code: row.code, selected: bool };
      // });
    } else if (tarGet == "hna1") {
      // this.hnaList = this.hnaList.map(row => {
      //   return { name: row.name, code: row.code, selected: bool };
      // });
    } else {
      if (!bool) {
        this.initObj[tarGet].selectCount = 0;
      }

      this.initObj[tarGet].items = this.initObj[tarGet].items.map(row => {
        if (tarGet == "shape") {
          return {
            name: row.name,
            code: row.code,
            selected: bool,
            className: row.className
          };
        } else {
          return { name: row.name, code: row.code, selected: bool };
        }
      });
    }
    if (!bool) {
      this.gdofr = false;
      this.ex3 = false;
      this.vg3 = false;
    }
  }

  onSubmit() {
    localStorage.setItem("SearchPara",JSON.stringify(this.createPostData()).toString())
    let ctn = localStorage.getItem('sctn')
    ctn  =( parseInt(ctn) + 1).toString();
    localStorage.setItem("sctn",ctn)
    this.TabBarServ.setData("Diamond Result (" + ctn + ")");
    this.isSearchNoActive = true;
    this.LoadGridData();
    this.clearCalculation();
  }

  unCheckAll(tarGet, TargetVal) {
    if (TargetVal != "All" && TargetVal.code != 0) {
      if (
        tarGet == "location" ||
        tarGet == "shade" ||
        tarGet == "fancyintensity" ||
        tarGet == "fancycolor" ||
        tarGet == "fancyovertone"
      ) {
        let AllFilter = this[tarGet + "List"].filter(item => item.code != 0);
        let selectedFilter = this.StockView.value[tarGet + "Control"].filter(
          item => item.code != 0
        );

        if (AllFilter.length == selectedFilter.length) {
          this.StockView.patchValue({
            [tarGet + "Control"]: [
              {
                name: "All",
                code: 0
              },
              ...this.StockView.value[tarGet + "Control"]
            ]
          });

          this["all" + tarGet + "Selected"] = true;
        } else if (AllFilter.length > selectedFilter.length) {
          this["all" + tarGet + "Selected"] = false;
          this.StockView.patchValue({
            [tarGet + "Control"]: this.StockView.value[
              tarGet + "Control"
            ].filter(item => item.code != 0)
          });
        }
      } else if (tarGet == "lust1") {
        // this.allLustSelected = !this.lustList.some(item => !item.selected);
      } else if (tarGet == "hna") {
        // this.allHNASelected = !this.hnaList.some(item => !item.selected);
      } else {
        //check if atlest one item is not selected
        this.initObj[tarGet].selectAll = !this.initObj[tarGet].items.some(
          item => !item.selected
        );
      }
    }
  }

  selectSame(Common) {
    if (Common == "3ex") {
      this.ex3 = !this.ex3;
      this.initObj.cut.items = this.initObj.cut.items.map(item =>
        item.name == "EX"
          ? {
              name: item.name,
              code: item.code,
              selected: this.ex3
            }
          : item
      );
      this.initObj.pol.items = this.initObj.pol.items.map(item =>
        item.name == "EX"
          ? {
              name: item.name,
              code: item.code,
              selected: this.ex3
            }
          : item
      );
      this.initObj.symm.items = this.initObj.symm.items.map(item =>
        item.name == "EX"
          ? {
              name: item.name,
              code: item.code,
              selected: this.ex3
            }
          : item
      );
    } else if (Common == "3vg") {
      this.vg3 = !this.vg3;
      this.initObj.cut.items = this.initObj.cut.items.map(item =>
        item.name == "VG"
          ? {
              name: item.name,
              code: item.code,
              selected: this.vg3
            }
          : item
      );
      this.initObj.pol.items = this.initObj.pol.items.map(item =>
        item.name == "VG"
          ? {
              name: item.name,
              code: item.code,
              selected: this.vg3
            }
          : item
      );
      this.initObj.symm.items = this.initObj.symm.items.map(item =>
        item.name == "VG"
          ? {
              name: item.name,
              code: item.code,
              selected: this.vg3
            }
          : item
      );
    } else if (Common == "gdofr") {
      this.gdofr = !this.gdofr;

      this.initObj.cut.items = this.initObj.cut.items.map(item =>
        item.name == "GD" || item.name == "FR"
          ? {
              name: item.name,
              code: item.code,
              selected: this.gdofr
            }
          : item
      );
      this.initObj.pol.items = this.initObj.pol.items.map(item =>
        item.name == "GD" || item.name == "FR"
          ? {
              name: item.name,
              code: item.code,
              selected: this.gdofr
            }
          : item
      );
      this.initObj.symm.items = this.initObj.symm.items.map(item =>
        item.name == "GD" || item.name == "FR"
          ? {
              name: item.name,
              code: item.code,
              selected: this.gdofr
            }
          : item
      );
    }else if (Common == "exminus") {
      this.exminus = !this.exminus;

      this.initObj.cut.items = this.initObj.cut.items.map(item =>
        item.name == "EX" || item.name == "VG"
          ? {
              name: item.name,
              code: item.code,
              selected: this.exminus
            }
          : item
      );
      this.initObj.pol.items = this.initObj.pol.items.map(item =>
        item.name == "EX" || item.name == "VG"
          ? {
              name: item.name,
              code: item.code,
              selected: this.exminus
            }
          : item
      );
      this.initObj.symm.items = this.initObj.symm.items.map(item =>
        item.name == "EX" || item.name == "VG"
          ? {
              name: item.name,
              code: item.code,
              selected: this.exminus
            }
          : item
      );
    }else if (Common == "vgthreeminus") {
      this.vgthreeminus = !this.vgthreeminus;

      this.initObj.cut.items = this.initObj.cut.items.map(item =>
        item.name == "VG" || item.name == "GD"
          ? {
              name: item.name,
              code: item.code,
              selected: this.vgthreeminus
            }
          : item
      );
      this.initObj.pol.items = this.initObj.pol.items.map(item =>
        item.name == "VG" || item.name == "GD"
          ? {
              name: item.name,
              code: item.code,
              selected: this.vgthreeminus
            }
          : item
      );
      this.initObj.symm.items = this.initObj.symm.items.map(item =>
        item.name == "VG" || item.name == "GD"
          ? {
              name: item.name,
              code: item.code,
              selected: this.vgthreeminus
            }
          : item
      );
    }
    else if(Common == "NBGM"){
     // new code
    }

    this.initObj["cut"].selectAll = !this.initObj["cut"].items.some(
      item => !item.selected
    );
    this.initObj["symm"].selectAll = !this.initObj["symm"].items.some(
      item => !item.selected
    );
    this.initObj["pol"].selectAll = !this.initObj["pol"].items.some(
      item => !item.selected
    );

  }

  keyPressNumbersWithDecimal(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }




  createPostData() {
    let finalPostData = {},
      FinalLocationPost,
      FinalShadePost;
    let finalColorPost = "";

    let commaJoiner = TargeT => {
      return this.initObj[TargeT].items
        .filter(item => item.selected)
        .map(item => item.code)
        .join(",");
    };
    let [
      finalcolorPost,
      finalclarityPost,
      finalfluorescencePost,
      finalcutPost,
      finallabPost,
      finalsymmPost,
      finalpolPost,
      finalShapePost,
      finalSizePost,
      finalEyePost,
      finalHAPost,
      finalLusterPost

    ] = [
        commaJoiner("color"),
        commaJoiner("clarity"),
        commaJoiner("fluorescence"),
        commaJoiner("cut"),
        commaJoiner("lab"),
        commaJoiner("symm"),
        commaJoiner("pol"),
        commaJoiner("shape"),
        commaJoiner("carat"),
        commaJoiner("eye"),
        commaJoiner("ha"),
        commaJoiner("luster")
      ];
      finalPostData["SessionId"]=this.decodedTkn.sessionId;
    finalPostData["shape_list"] = finalShapePost;
    finalPostData["from_carat"] = this.StockView.value.from_carat ? this.StockView.value.from_carat : 0;
    finalPostData["to_carat"] = this.StockView.value.to_carat ? this.StockView.value.to_carat : 0;
    finalPostData["stoneid_list"] = this.StockView.value.stoneid_list ? this.StockView.value.stoneid_list.split(/[ ,]+/).toString().replace('\n', ',') : "";
    finalPostData["certificate_list"] = this.StockView.value.certificate_list ? this.StockView.value.certificate_list.split(/[ ,]+/).toString().replace('\n', ',') : "";
    finalPostData["size_list"] =  finalSizePost;
    let _DiamondType = this.whiteColor == true ? 0 : 1
    finalPostData["DiamondType"] = _DiamondType;

    if (this.whiteColor) {
      finalPostData["color_list"] =  finalcolorPost;
    } else {
      finalPostData["fancyintensity"] = this.StockView.value.fancyintensityControl
        .map(item => item.name)
        .filter(item => item.toUpperCase() != "ALL")
        .join(",");
      finalPostData["fancyovertone"] = this.StockView.value.fancyovertoneControl
        .map(item => item.name)
        .filter(item => item.toUpperCase() != "ALL")
        .join(",");
      finalPostData["fancycolor"] = this.StockView.value.fancycolorControl
        .map(item => item.name)
        .filter(item => item.toUpperCase() != "ALL")
        .join(",");
    }

    if (this.StockView.value.shadeControl.length > 1) {
      FinalShadePost = this.StockView.value.shadeControl.reduce(
        (commaSep, Elem) => {
          if (typeof commaSep === "object") {
            return parseInt(commaSep.code) == 0
              ? Elem.code
              : commaSep.code + "," + Elem.code;
          } else {
            return commaSep + "," + Elem.code;
          }
        }
      );
    } else {
      FinalShadePost =
        this.StockView.value.shadeControl.length == 0
          ? ""
          : this.StockView.value.shadeControl[0].code;
    }


    finalPostData["clarity_list"] =  finalclarityPost;
    finalPostData["cut_list"] =  finalcutPost;
    finalPostData["polish_list"] =  finalpolPost;
    finalPostData["symm_list"] =  finalsymmPost;
    finalPostData["flo_list"] =  finalfluorescencePost;
    finalPostData["eyeclean_list"] =  finalEyePost;
    finalPostData["from_price"] = this.StockView.value.from_price ? this.StockView.value.from_price : 0;
    finalPostData["to_price"] = this.StockView.value.to_price ? this.StockView.value.to_price : 0;
    finalPostData["from_per"] = this.StockView.value.from_per ? this.StockView.value.from_per : 0;
    finalPostData["to_per"] = this.StockView.value.to_per ? this.StockView.value.to_per : 0;
    finalPostData["ha_list"] =  finalHAPost;
    finalPostData["luster_list"] =  finalLusterPost;
    finalPostData["shade_list"] =  FinalShadePost;


    if (this.StockView.value.locationControl.length > 1) {
      FinalLocationPost = this.StockView.value.locationControl.reduce(
        (commaSep, Elem) => {
          if (typeof commaSep === "object") {
            return parseInt(commaSep.code) == 0
              ? Elem.code
              : commaSep.code + "," + Elem.code;
          } else {
            return commaSep + "," + Elem.code;
          }
        }
      );
    } else {
      FinalLocationPost =
        this.StockView.value.locationControl.length == 0
          ? ""
          : this.StockView.value.locationControl[0].code;
    }

    finalPostData["location_list"] = FinalLocationPost;
    finalPostData["cert_list"] = finallabPost;

    finalPostData["from_diamin"] = this.StockView.value.from_diamin ? this.StockView.value.from_diamin : 0;
    finalPostData["to_diamin"] = this.StockView.value.to_diamin ? this.StockView.value.to_diamin : 0;

    finalPostData["from_diamax"] = this.StockView.value.from_diamax ? this.StockView.value.from_diamax : 0;
    finalPostData["to_diamax"] = this.StockView.value.to_diamax ? this.StockView.value.to_diamax : 0;

    finalPostData["from_diaheight"] = this.StockView.value.from_diaheight ? this.StockView.value.from_diaheight : 0;
    finalPostData["to_diaheight"] = this.StockView.value.to_diaheight ? this.StockView.value.to_diaheight : 0;

    finalPostData["from_totaldepth"] = this.StockView.value.from_totaldepth ? this.StockView.value.from_totaldepth : 0;
    finalPostData["to_totaldepth"] = this.StockView.value.to_totaldepth ? this.StockView.value.to_totaldepth : 0;

    finalPostData["from_table"] = this.StockView.value.from_table ? this.StockView.value.from_table : 0;
    finalPostData["to_table"] = this.StockView.value.to_table ? this.StockView.value.to_table : 0;

    finalPostData["from_crownangle"] = this.StockView.value.from_crownangle ? this.StockView.value.from_crownangle : 0;
    finalPostData["to_crownangle"] = this.StockView.value.to_crownangle ? this.StockView.value.to_crownangle : 0;

    finalPostData["from_crownheight"] = this.StockView.value.from_crownheight ? this.StockView.value.from_crownheight : 0;
    finalPostData["to_crownheight"] = this.StockView.value.to_crownheight ? this.StockView.value.to_crownheight : 0;

    finalPostData["from_pavangle"] = this.StockView.value.from_pavangle ? this.StockView.value.from_pavangle : 0;
    finalPostData["to_pavangle"] = this.StockView.value.to_pavangle ? this.StockView.value.to_pavangle : 0;

    finalPostData["from_pavheight"] = this.StockView.value.from_pavheight ? this.StockView.value.from_pavheight : 0;
    finalPostData["to_pavheight"] = this.StockView.value.to_pavheight ? this.StockView.value.to_pavheight : 0;

    finalPostData["from_gridle"] = this.StockView.value.from_gridle ? this.StockView.value.from_gridle : 0;
    finalPostData["to_gridle"] = this.StockView.value.to_gridle ? this.StockView.value.to_gridle : 0;

    return finalPostData;

  }


  compareObjects(o1: any, o2: any): boolean {
    return o1.name === o2.name && o1._id === o2._id;
  }

  selectSingleChipOption(valueObj, Target) {
    if (valueObj == "All" || valueObj.code == 0) {
      if (this["all" + Target + "Selected"]) {
        this.StockView.controls[Target + "Control"].setValue([]);
      } else {
        this.StockView.controls[Target + "Control"].setValue(
          this[Target + "List"]
        );
      }
      this["all" + Target + "Selected"] = !this["all" + Target + "Selected"];
    }
  }

  resetForm(){

    this.SearchCountDigit = 0;

    this.ex3 = false;
    this.vg3 = false;
    this.gdofr = false;

    this.initObj = {
      shape: {
        selectAll: false,
        selectCount: 0,
        items: [
          {
            name: "Round",
            code: 3,
            selected: true,
            className: "icon-Round1"
          },
          {
            name: "Emerald",
            code: 7,
            selected: false,
            className: "icon-Emerald3"
          },
          {
            name: "Cushion",
            code: 6,
            selected: false,
            className: "icon-Cushion1"
          },
          {
            name: "Princess",
            code: 5,
            selected: false,
            className: "icon-ico_Princess"
          },
          {
            name: "Marquise",
            code: 11,
            selected: false,
            className: "icon-Marquise1"
          },
          {
            name: "Pear",
            code: 14,
            selected: false,
            className: "icon-Pear1"
          },
          {
            name: "Heart",
            code: 9,
            selected: false,
            className: "icon-Heart3"
          },
          {
            name: "Oval",
            code: 12,
            selected: false,
            className: "icon-Oval3"
          },
          {
            name: "SqEmerald",
            code: 8,
            selected: false,
            className: "icon-Square-Emerald1"
          },
          {
            name: "Radient",
            code: 16,
            selected: false,
            className: "icon-LgRadiant3"
          },
          {
            name: "Other Shapes",
            code: 1,
            selected: false,
            className: "icon-other"
          }
        ],
      },
      carat:{
        selectCount: 0,
        items: [
          { SizeRange: "0.01-0.14", selected: false , code: 1 },
          { SizeRange: "0.15-0.29", selected: false , code: 2 },
          { SizeRange: "0.30-0.39", selected: false , code: 3 },
          { SizeRange: "0.40-0.49", selected: false , code: 4 },
          { SizeRange: "0.50-0.69", selected: false , code: 5 },
          { SizeRange: "0.70-0.89", selected: false , code: 6 },
          { SizeRange: "0.90-0.99", selected: false , code: 7 },
          { SizeRange: "1.00-1.49", selected: false , code: 8 },
          { SizeRange: "1.50-1.99", selected: false , code: 9 },
          { SizeRange: "2.00-2.99", selected: false , code: 10 },
          { SizeRange: "3.00-4.99", selected: false , code: 11 },
          { SizeRange: "5.00-5.99", selected: false , code: 12 }
        ]
      },
      color: {
        selectAll: false,
        selectCount: 0,
        items: [
          { name: "D", code: 1, selected: false },
          { name: "E", code: 2, selected: false },
          { name: "F", code: 3, selected: false },
          { name: "G", code: 4, selected: false },
          { name: "H", code: 5, selected: false },
          { name: "I", code: 6, selected: false },
          { name: "J", code: 7, selected: false },
          { name: "K", code: 8, selected: false },
          { name: "L", code: 9, selected: false },
          { name: "M", code: 10, selected: false },
          { name: "N", code: 11, selected: false },
          { name: "O-P", code: 96, selected: false },
          { name: "Q-R", code: 98, selected: false },
          { name: "S-T", code: 99, selected: false },
          { name: "U-V", code: 100, selected: false },
          { name: "W-X", code: 101, selected: false },
          { name: "Y-Z", code: 102, selected: false }
        ],
      },
      clarity: {
        selectAll: false,
        items: [
          { name: "FL", code: 1, selected: false },
          { name: "IF", code: 3, selected: false },
          { name: "VVS1", code: 4, selected: false },
          { name: "VVS2", code: 5, selected: false },
          { name: "VS1 ", code: 6, selected: false },
          { name: "VS2", code: 7, selected: false },
          { name: "SI1", code: 8, selected: false },
          { name: "SI2", code: 9, selected: false },
          { name: "SI3", code: 10, selected: false },
          { name: "I1", code: 11, selected: false },
          { name: "I2", code: 15, selected: false },
          { name: "I3", code: 13, selected: false }
        ],
        selectCount: 0
      },
      cut: {
        selectAll: false,
        items: [
          { name: "EX", code: 1, selected: false },
          { name: "VG", code: 2, selected: false },
          { name: "GD", code: 3, selected: false },
          { name: "FR", code: 4, selected: false }
        ],
        selectCount: 0
      },
      pol: {
        selectAll: false,
        items: [
          { name: "EX", code: 1, selected: false },
          { name: "VG", code: 2, selected: false },
          { name: "GD", code: 4, selected: false },
          { name: "FR", code: 5, selected: false }
        ],
        selectCount: 0
      },
      symm: {
        selectAll: false,
        items: [
          { name: "EX", code: 1, selected: false },
          { name: "VG", code: 2, selected: false },
          { name: "GD", code: 3, selected: false },
          { name: "FR", code: 4, selected: false }
        ],
        selectCount: 0
      },
      fluorescence: {
        selectAll: false,
        items: [
          { name: "NONE", code: 1, selected: false },
          { name: "FAINT", code: 4, selected: false },
          { name: "MEDIUM", code: 2, selected: false },
          { name: "STRONG", code: 3, selected: false },
          { name: "VERY STRONG", code: 5, selected: false }
        ],
        selectCount: 0
      },
      eye: {
        selectAll: false,
        items: [
          { name: "Yes", code: 3, selected: false },
          { name: "No", code: 2, selected: false },
        ],
        selectCount: 0
      },
      luster: {
        selectAll: false,
        items: [
          { name: "EX", code: 1, selected: false },
          { name: "VG", code: 2, selected: false },
          { name: "ML1", code: 3, selected: false },
          { name: "ML2", code: 4, selected: false },
          { name: "ML3", code: 5, selected: false },
          { name: "G", code: 6, selected: false },
        ],
        selectCount: 0
      },
      ha: {
        selectAll: false,
        items: [
          { name: "EX", code: 1, selected: false },
          { name: "VG", code: 2, selected: false }
        ],
        selectCount: 0
      },
      lab: {
        selectAll: false,
        items: [
          { name: "GIA", code: 3, selected: false },
          { name: "HRD", code: 2, selected: false },
          { name: "IGI", code: 4, selected: false },         
          { name: "AGS", code: 6, selected: false },
          { name: "EGL", code: 7, selected: false },
          { name: "IGC", code: 8, selected: false },          
          { name: "NGTC", code: 10, selected: false },           
          { name: "GSI", code: 13, selected: false }
        ],
        selectCount: 0
      },
    };

    this.StockView.patchValue({
      locationControl: [[]],

      fancyintensityControl: [[]],
      fancyovertoneControl: [[]],
      fancycolorControl: [[]],
      shadeControl:[[]],
      from_carat:null,
      to_carat:null,
      from_diamin:null,
      to_diamin:null,
      from_table:null,
      to_table:null,
      from_pavheight:null,
      to_pavheight:null,
      from_diamax:null,
      to_diamax:null,
      from_diaheight:null,
      to_diaheight:null,
      from_totaldepth:null,
      to_totaldepth:null,
      from_crownangle:null,
      to_crownangle:null,
      from_crownheight:null,
      to_crownheight:null,
      from_pavangle:null,
      to_pavangle:null,
      from_gridle:null,
      to_gridle:null,
      from_price:null,
      to_price:null,
      from_per:null,
      to_per:null,
      stoneid_list:null,
      certificate_list:null
    });

  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.LoadGridData()
  }

  onSelectionChanged(event: any) {
    this.selectedRow = event.api.getSelectedRows();
    this.doCalculation();
  }

  onGridRowClicked(eve: any) {
    if (eve.event.target !== undefined) {
      let actionType = eve.event.target.getAttribute("data-action-type");

      if (actionType == "photo") {
        this.OpenDetailModal("photo", eve.data.ImageUrl);
      } else if (actionType == "video") {
        this.OpenDetailModal("video", eve.data.VideoUrl);
      } else if (actionType == "certificat") {
        this.OpenDetailModal("certificat", eve.data.CertPDFUrl);
      } else if (actionType == "StoneDet") {
        this.OpenStoneDeatilModal(eve.data)
      }
    }
  }

  doCalculation() {
    if (this.selectedRow.length == 0) {
      this.totalCts = 0;
      this.avgRap = 0;
      this.avgDisc = 0;
      this.ctRate = 0;
      this.totAmount = 0;
    } else {
      this.totalCts = this.selectedRow.reduce((acc, val) => {
        return acc + parseFloat(val.Carat);
      }, 0);
      this.totalCts = this.totalCts.toFixed(2);

      this.avgRap = this.selectedRow.reduce((acc, val) => {
        return acc + parseFloat(val.Carat) * parseFloat(val.RapRate);
      }, 0);
      this.avgRap /= this.totalCts;
      this.avgRap = this.avgRap.toFixed(2);

      this.totAmount = this.selectedRow.reduce((acc, val) => {
        return acc + parseFloat(val.Amount);
      }, 0);
      this.totAmount = this.totAmount.toFixed(2);

      this.ctRate = this.selectedRow.reduce((acc, val) => {
        return acc + parseFloat(val.Carat) * parseFloat(val.PricePerCarat);
      }, 0);
      this.ctRate /= this.totalCts;
      this.ctRate = this.ctRate.toFixed(2);

      this.avgDisc = 100 - (this.ctRate / this.avgRap) * 100;
      this.avgDisc = this.avgDisc.toFixed(2);
    }
  }

  // detail modal starts
  matDialogRefDteails: MatDialogRef<ImgVideoComponent>;

  OpenDetailModal(ModalTopic, FilePath) {
    this.matDialogRefDteails = this.matDialog.open(ImgVideoComponent, {
      data: { ModalTopic, FilePath },
      disableClose: false
    });
  }
  // detail modal ends

  //stone deatails modal starts
  matDialogStoneDetail: MatDialogRef<DiamondDetailComponent>
  OpenStoneDeatilModal(StoneDetails:any){
    this.matDialogStoneDetail = this.matDialog.open(DiamondDetailComponent,{
      data:{StoneDetails:StoneDetails},
        disableClose:false,
        width: '90%',
        height: '90%',
        panelClass: 'stonde-details-modalbox'
    })
  }
  //stone details modal end


  ModifiySearch(){
    this.TabBarServ.setData("Diamond Search");
    this.isSearchNoActive = false;
  }

  addToCart(){
    this.spinner.show();

    if(this.selectedRow.length == 0){
      this.spinner.hide()
      this.toastr.warning("Select at least one stone")
      return
    }

    var selectedStoneId = this.selectedRow.map(function(val) {
      return val.StockNo;
    }).join(',');

    var payload = {
      "StockNo":selectedStoneId,
      "SessionId":this.decodedTkn.sessionId
    }

    this.DiamondSearchServ.AddToCart(payload).subscribe(ResultRes => {
        try {
          if (ResultRes.success == 1) {
            this.spinner.hide();
            this.clearCalculation();
            this.toastr.success("Stone(s) added to cart successfully");
            return;
          } else {
            this.clearCalculation();
            this.spinner.hide()
          }
        } catch (error) {
          console.log(error);
          this.spinner.hide();
          this.clearCalculation();
        }
    });
  }

  excelExport(){
    this.spinner.show();

    if(this.selectedRow.length == 0){
      this.spinner.hide()
      this.toastr.warning("Select at least one stone")
      return
    }

    var selectedStoneId = this.selectedRow.map(function(val) {
      return val.DiamondId;
    }).join(',');

    var mapForm = document.createElement("form");
    mapForm.method = "POST"; // or "post" if appropriate
    mapForm.action = this.apiUrl + 'diamondSearch/ExcelExport';

    var SLmapInput = document.createElement("input");
    SLmapInput.type = "hidden";
    SLmapInput.name = "DiamondIdList";
    SLmapInput.setAttribute("value", selectedStoneId);
    mapForm.appendChild(SLmapInput);

    var UIDmapInput = document.createElement("input");
    UIDmapInput.type = "hidden";
    UIDmapInput.name = "UserID";
    UIDmapInput.setAttribute("value", this.decodedTkn.UserID);
    mapForm.appendChild(UIDmapInput);

    document.body.appendChild(mapForm);
    mapForm.submit();

    this.spinner.hide()
  }

  clearCalculation() {
    this.gridApi.deselectAll();
    this.totalCts = 0;
    this.avgRap = 0;
    this.avgDisc = 0;
    this.ctRate = 0;
    this.totAmount = 0;
  }

  LoadGridData(){
    let SearchPara = JSON.parse(localStorage.getItem("SearchPara"));
    if(SearchPara == null){
      return;
    }
    this.spinner.show()
    this.DiamondSearchServ.DiamondSearchResult(SearchPara).subscribe(ResultRes => {
      localStorage.removeItem('SearchPara')
        try {
          if (ResultRes.success == 1) {
            this.gridApi.setRowData(ResultRes.data);
            this.spinner.hide()
          } else {
            this.spinner.hide()
          }
        } catch (error) {
          console.log(error);
          this.spinner.hide()
        }
    });
  }

  langtranslator(lan)
  {
    sessionStorage.setItem('set_lang',lan);
    this.set_lang=sessionStorage.getItem('set_lang');
    this.translate.use(lan);
  }

  public localizeHeader(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.headerName;
    return this.translate.instant(headerIdentifier);
  }

  GenerateStoneOrder(){
    if(this.selectedRow.length == 0){
      this.toastr.warning("Select at list one stone.")
      return;
    }

    let ChkStoneNotAva = this.selectedRow.filter((option)=> option.DiamondStatus != 1)

    if(ChkStoneNotAva.length != 0){
      this.toastr.warning("Remove business process stone.")
      return;
    }

    let CartList;

    CartList = this.selectedRow.map((item)=>{
      return {
        DiamondId:item.DiamondId,
        StockNo:item.StockNo,
        VStockNo:item.VStockNo,
        ReptNo:item.ReptNo,
        VendorId:item.VendorId,
        StockRefNo:item.StockRefNo,
        LocationId:item.LocationId,
        SessionId:this.decodedTkn.sessionId
      }
    })

  var payLoad = {
    CartList : CartList
    }

    this.orderService.OrderSave(payLoad).subscribe(ResultRes => {
      try {
        if (ResultRes.success == 1) {
          this.spinner.hide();
          this.clearCalculation();
          this.toastr.success("Stone(s) Confirmed successfully");
          return;
        } else {
          this.toastr.error("Somthing went wrong");
          this.clearCalculation();
          this.spinner.hide()
        }
      } catch (error) {
        console.log(error);
        this.spinner.hide();
        this.clearCalculation();
      }
  });


  }

  SearchCount(){
    this.DiamondSearchServ.DiamondSearchCount(this.createPostData()).subscribe((CRes)=>{
      try {
        if(CRes.success == true){
          this.SearchCountDigit = CRes.data[0]['Total']
        }else{
          this.toastr.error("Something went wrong while displaying this search count");
        }
      } catch (error) {
        this.toastr.error(error)
      }
    });
  }

  matDialogRefSaveSeach: MatDialogRef<SaveSearchDemandModalComponent>;
  SaveSearch(){
    this.matDialogRefSaveSeach = this.matDialog.open(SaveSearchDemandModalComponent, {
      data: { PostData:this.createPostData(), OpeTye:'SS' },
      disableClose: false
    });
  }

  matDialogRefDemand: MatDialogRef<SaveSearchDemandModalComponent>;
  SaveDemand(){
    this.matDialogRefDemand = this.matDialog.open(SaveSearchDemandModalComponent, {
      data: { PostData:this.createPostData(), OpeTye:'SD' },
      disableClose: false
    });
  }
}
