import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginService } from '../Service/login.service';
import * as $ from "jquery";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
   decodeHelper = new JwtHelperService();
   decodedTkn:any;
   RememberMe:boolean;
   Password:any;
   UserId:any;
  constructor(
    private loginServ:LoginService,
    private router:Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    this.loginForm = new FormGroup({
      UserId: new FormControl(""),
      password: new FormControl(""),
      RememberMe: new FormControl("")
    });
   }

  ngOnInit() {

    $("body").removeClass("diamond-search");
    $('#pp-nav').removeAttr('id');

    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('indexContainer');


    if(localStorage.getItem('token')){
      this.router.navigateByUrl('dashbaord');
    }else{
      this.router.navigateByUrl('login');
    }

    if(JSON.parse(localStorage.getItem('RememberMe')) !== null)
    {
      this.loginForm.value.UserId = localStorage.getItem('pshu');
      this.loginForm.value.Password = localStorage.getItem('pshp');
      this.loginForm.value.RememberMe = JSON.parse(localStorage.getItem('RememberMe'));
      this.UserId = localStorage.getItem('pshu');
      this.Password = localStorage.getItem('pshp');
      this.RememberMe = true;
    }
  }

  onSubmit(){
    if(this.loginForm.value.UserId == ''){
      this.toastr.warning('Enter User Name.');
      return;
    }
    if(this.loginForm.value.password == ''){
      this.toastr.warning('Enter Password.');
      return;
    }
    this.spinner.show()
    this.loginServ.Login({ login_name:this.loginForm.value.UserId,password:this.loginForm.value.password,app_code:1 }).subscribe(LoginRes => {
      try{
        if(LoginRes.success == 1){
          this.spinner.hide();
          localStorage.setItem('token',LoginRes.data)
          localStorage.setItem('sctn','0')
          if(this.loginForm.value.RememberMe){
            localStorage.setItem('pshu', this.loginForm.value.UserId);
            localStorage.setItem('pshp', this.loginForm.value.password);
            localStorage.setItem('RememberMe', JSON.stringify(this.loginForm.value.RememberMe));
          }

          this.decodedTkn = this.decodeHelper.decodeToken(localStorage.getItem('token'));
          localStorage.setItem('sessionId',this.decodedTkn.sessionId)
          //this.router.navigateByUrl('home');
          this.router.navigateByUrl("/dashbaord");
        }else if(LoginRes.success == 2){
          this.spinner.hide()
          this.toastr.info(LoginRes.data)
        }else{
          this.spinner.hide()
          this.toastr.info(LoginRes.data)
        }
      }catch(error){
        this.spinner.hide()
        this.toastr.error(error)
      }
    })
  }

  closeLogin(){
    this.router.navigate(['/index'])
  }

  redirectToRegistration(){
    this.router.navigate(['/register'])
  }

  redirectToForgotPass(){
    this.router.navigate(['/forgotpassword'])
  }

  
  redirectToLogin() {
    this.router.navigate(['/login'])
  }

  redirectToProduct() {
    this.router.navigate(['/products'])
  }

  redirectToQuotation(){
    this.router.navigate(['/quotation'])
  }

  redirectToIndex(){
    this.router.navigate(['/index'])
  }

  redirectToAboutUs(){
    this.router.navigate(['/aboutus'])
  }

  redirectToContactUs(){
    this.router.navigate(['/contact'])
  }

  redirectToTerms(){
    this.router.navigate(['/terms'])
  }
}
