import { BrowserModule } from '@angular/platform-browser';
import { NgModule , CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';

import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule,HTTP_INTERCEPTORS,HttpClient} from "@angular/common/http";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import {DatePipe} from "@angular/common";
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AuthInterceptor } from './Service/auth/auth.interceptor';
import { AgGridModule } from 'ag-grid-angular';

import { MaterialModule } from './MaterialModule/material-module';
import { LoginComponent } from './login/login.component';
import { SideBarComponent } from './User/side-bar/side-bar.component';
import { TabBarComponent } from './User/tab-bar/tab-bar.component';
import { DiamondSearchComponent } from './User/diamond-search/diamond-search.component';
import { DashboardComponent } from './User/dashboard/dashboard.component';
import { DiamondResultComponent } from './User/diamond-result/diamond-result.component';
import { ImgVideoComponent } from './User/img-video/img-video.component';
import { DiamondDetailComponent } from './User/diamond-detail/diamond-detail.component';
import { DiamondCartComponent } from './User/diamond-cart/diamond-cart.component';
import { PendingOrderComponent } from './User/pending-order/pending-order.component';
import { OrderSummaryComponent } from './User/order-summary/order-summary.component';
import { OrderSummaryDetailComponent } from './User/order-summary-detail/order-summary-detail.component';
import { DiamondDdcComponent } from './User/diamond-ddc/diamond-ddc.component';
import { ChangePassowrdComponent } from './AccountSetting/change-passowrd/change-passowrd.component';
import { IndexComponent } from './index/index.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { EditProfileComponent } from './AccountSetting/edit-profile/edit-profile.component';
import { SaveSearchDemandModalComponent } from './User/diamond-search/save-search-demand-modal/save-search-demand-modal.component';
import { SaveSearchViewComponent } from './User/save-search-view/save-search-view.component';
import { StockDemandComponent } from './User/stock-demand/stock-demand.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactComponent } from './contact/contact.component';
import { ContactusComponent } from './contactus/contactus.component';
import { ProductsComponent } from './products/products.component';
import { QuotationComponent } from './quotation/quotation.component';
import { TermsComponent } from './terms/terms.component';

export function TranslationLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http,"../assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SideBarComponent,
    TabBarComponent,
    DiamondSearchComponent,
    DashboardComponent,
    DiamondResultComponent,
    ImgVideoComponent,
    DiamondDetailComponent,
    DiamondCartComponent,
    PendingOrderComponent,
    OrderSummaryComponent,
    OrderSummaryDetailComponent,
    DiamondDdcComponent,
    ChangePassowrdComponent,
    IndexComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    EditProfileComponent,
    SaveSearchDemandModalComponent,
    SaveSearchViewComponent,
    StockDemandComponent,
    AboutusComponent,
    ContactComponent,
    ContactusComponent,
    ProductsComponent,
    QuotationComponent,
    TermsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    AgGridModule.withComponents([]),
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useFactory: TranslationLoaderFactory, deps: [HttpClient]}
    })
  ],
  providers: [
    DatePipe,
  { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  {provide: HTTP_INTERCEPTORS,useClass: AuthInterceptor,multi: true}
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents:[
    DashboardComponent,
    DiamondSearchComponent,
    DiamondResultComponent,
    ImgVideoComponent,
    DiamondDetailComponent,
    DiamondCartComponent,
    PendingOrderComponent,
    OrderSummaryComponent,
    OrderSummaryDetailComponent,
    DiamondDdcComponent,
    ChangePassowrdComponent,
    EditProfileComponent,
    SaveSearchDemandModalComponent,
    SaveSearchViewComponent,
    StockDemandComponent
  ],

})
export class AppModule { }
