import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { DatePipe } from '@angular/common'

import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { RegisterService } from '../../Service/register.service';
import * as $ from "jquery";

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {


  CountryArr=[]
  StateArr=[]
  CityArr=[]

  UserName:any=''
  Password:any=''
  C_Password:any=''
  Title:any=''
  FirstName:any=''
  MiddleName:any=''
  LastName:any=''
  Designation:any=''
  BirthDate:any=''
  PhoneNo:any=''
  AlternativePhoneNo:any=''
  Email:any=''
  AlternativeEmail:any=''
  Fax:any=''
  SkypeId:any=''
  QQId:any=''
  CompanyName:any=''
  CompanyAddress:any=''
  C_CityID:any=''
  C_StateID:any=''
  C_CountryID:any=''
  C_PostalCode:any=''
  TC:boolean=false
  PassportNO:any=''
  Default_Lang:any='en'

  constructor(
    private router:Router,
    private RegisterServ : RegisterService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private datePipe : DatePipe
  ) { }

  ngOnInit() {
    $("body").addClass("diamond-search");
    this.CountryFill();
    this.GetUserDetail();
    setTimeout(() => {
      if(($('.sidebar-container').hasClass('sidebar-is-expanded')) == true){
        $('#imgLogo').attr("src","../../../assets/PSHNew/img/psh-dashbaord-big-logo.png");
        $("#imgLogo").addClass("largeLogo");
      }else{
        $('#imgLogo').attr("src","../../../assets/PSHNew/img/psh-dashbaord-small-logo.png");
        $("#imgLogo").removeClass("largeLogo");
      } 
    }, 700);
  }


  GetUserDetail(){
    this.RegisterServ.GetUserDetail().subscribe((Res)=>{
      this.RegisterServ.StateFill({CountryID:Res.data[0].C_CountryID}).subscribe((SRes)=>{
        try {
          if(SRes.success == true){
            this.StateArr = SRes.data.map((item)=>{
              return {code:item.StateID,value:item.StateName}
            })
            this.C_StateID = Res.data[0].C_StateID;
          }
        } catch (error) {
          console.log(error)
        }
      })

      try{
        if(Res.success == true){
          this.UserName = Res.data[0].UserName;
          this.Title = Res.data[0].Title;
          this.FirstName = Res.data[0].FirstName;
          this.MiddleName = Res.data[0].MiddleName;
          this.LastName = Res.data[0].LastName;
          this.Designation = Res.data[0].Designation;
          this.BirthDate = Res.data[0].BirthDate;
          this.PhoneNo = Res.data[0].PhoneNo;
          this.AlternativePhoneNo = Res.data[0].AlternativePhoneNo;
          this.Email = Res.data[0].Email;
          this.AlternativeEmail = Res.data[0].AlternativeEmail;
          this.Fax = Res.data[0].Fax;
          this.SkypeId = Res.data[0].SkypeId;
          this.QQId = Res.data[0].QQId;
          this.CompanyName = Res.data[0].CompanyName;
          this.CompanyAddress = Res.data[0].CompanyAddress;
          this.C_CityID = Res.data[0].C_CityID;
          this.C_StateID = Res.data[0].C_StateID;
          this.C_CountryID = Res.data[0].C_CountryID;
          this.C_PostalCode = Res.data[0].C_PostalCode;
          this.PassportNO = Res.data[0].PassportNo;
          this.Default_Lang = Res.data[0].Default_Lang ? Res.data[0].Default_Lang : 'en';
        }
      }catch(error){
        console.log(error);

      }
    })
  }


  CountryFill(){
    this.RegisterServ.CountyFill({}).subscribe((CRes)=>{
      try{
        if(CRes.success == true){
          this.CountryArr = CRes.data.map((item)=>{
            return {code:item.CountryID,value:item.CountryName}
          })
        }
      }catch(error){
        console.log(error);

      }
    })
  }

  StateFill(){
    this.RegisterServ.StateFill({CountryID:this.C_CountryID}).subscribe((SRes)=>{
      try {
        if(SRes.success == true){
          this.StateArr = SRes.data.map((item)=>{
            return {code:item.StateID,value:item.StateName}
          })
        }
      } catch (error) {
        console.log(error)
      }
    })
  }

  CityFill(){
    this.RegisterServ.CityFill({StateID:this.C_StateID}).subscribe((CRes)=>{
      try {
        if(CRes.success == true){
          this.CityArr = CRes.data.map((item)=>{
            return {code:item.CityID,value:item.CityName}
          })
        }
      } catch (error) {
        console.log(error)
      }
    })
  }

  Save(){
    if(this.FirstName.trim() == ""){
      this.toastr.warning("Enter First Name");
      return;
    }
    if(this.LastName.trim() == ""){
      this.toastr.warning("Enter Last Name");
      return;
    }
    if(this.UserName.trim() == ""){
      this.toastr.warning("Enter User Name");
      return;
    }

    if(this.Email.trim() == ""){
      this.toastr.warning("Enter Email");
      return;
    }
    if(this.PhoneNo.trim() == ""){
      this.toastr.warning("Enter Phone Number");
      return;
    }


    let SaveObj={
      UserName:this.UserName.trim() ,
      Title:this.Title ? this.Title : '',
      FirstName:this.FirstName,
      MiddleName:this.MiddleName ? this.MiddleName : '',
      LastName:this.LastName,
      Designation:this.Designation,
      BirthDate:this.BirthDate ? this.datePipe.transform(this.BirthDate ,'yyyy-MM-dd'):'',
      PhoneNo:this.PhoneNo,
      AlternativePhoneNo:this.AlternativePhoneNo ? this.AlternativePhoneNo : '' ,
      Email:this.Email,
      AlternativeEmail: this.AlternativeEmail? this.AlternativeEmail : '',
      Fax:this.Fax ?  this.Fax : '',
      SkypeId: this.SkypeId ? this.SkypeId : '',
      QQId: this.QQId ? this.QQId : '',
      CompanyName : this.CompanyName ? this.CompanyName : '',
      CompanyAddress:this.CompanyAddress ? this.CompanyAddress : '',
      C_CityID :this.C_CityID,
      C_StateID: this.C_StateID,
      C_CountryID:this.C_CountryID,
      C_PostalCode:this.C_PostalCode,
      PassportNO:this.PassportNO ? this.PassportNO : '',
      Default_Lang:this.Default_Lang ? this.Default_Lang : 'en'
    }
    this.spinner.show()
    this.RegisterServ.UpdateUserDetail(SaveObj).subscribe((SRes)=>{
      try{
        if(SRes.success == true){
          this.spinner.hide()
          this.toastr.success('Your profile updated successfully');

        }else{
          this.spinner.hide()
          this.toastr.warning('Something gone wrong while registration')
        }
      }catch(err){
        this.spinner.hide()
        this.toastr.error(err)
      }
    })
  }

}
