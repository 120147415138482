import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DiamondSearchService {

  constructor(private http: HttpClient) { }

  private BaseUrl = environment.BaseUrl;

  SearchMasterFill(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'diamondSearch/SearchMasterFill',Data);
  }

  DiamondSearchResult(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'diamondSearch/DiamondSearchResult',Data);
  }

  AddToCart(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'diamondSearch/AddToCart',Data);
  }

  ExcelExport(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'diamondSearch/ExcelExport',Data);
  }

  DiamondSearchCount(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'diamondSearch/DiamondSearchCount',Data);
  }
}
