import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { SaveSearchService } from '../../../Service/User/SaveSeach/save-search.service';
import { DiamondDemandService } from '../../../Service/User/diamond-demand/diamond-demand.service';

@Component({
  selector: 'app-save-search-demand-modal',
  templateUrl: './save-search-demand-modal.component.html',
  styleUrls: ['./save-search-demand-modal.component.css']
})
export class SaveSearchDemandModalComponent implements OnInit {

  ModalStateData:any=''

  Title:any=''

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _mdr: MatDialogRef<SaveSearchDemandModalComponent>,
    private SaveSearchServ : SaveSearchService,
    private spinner: NgxSpinnerService,
    private toastr : ToastrService,
    private DiamondDemandServ : DiamondDemandService
  ) {
      this.ModalStateData = data
   }

  ngOnInit() {
  }

  Close(){
    this._mdr.close()
  }

  SaveData(OpeType:any){
    if(this.Title == ''){
      this.toastr.warning('Please enter name');
      return;
    }
    if(OpeType == 'SS'){
      this.spinner.show()
      this.ModalStateData.PostData['saved_search_name'] = this.Title
      this.SaveSearchServ.DiamondSavedSearch(this.ModalStateData.PostData).subscribe((SRes)=>{
        this.spinner.hide()
        try {
          if(SRes.success == true){
            this.toastr.success('Save search successfully.')
            this.Close()
          }else{
            this.toastr.warning('Something went wrong while saving this search data')
          }
        } catch (error) {
          this.toastr.error(error)
        }
      })
    }else if(OpeType == 'SD'){
      this.spinner.show()
      this.ModalStateData.PostData['demand_name'] = this.Title
      this.DiamondDemandServ.DiamondDemandSave(this.ModalStateData.PostData).subscribe((SRes)=>{
        this.spinner.hide()
        try {
          if(SRes.success == true){
            this.toastr.success('Save search successfully.')
            this.Close()
          }else{
            this.toastr.warning('Something went wrong while saving this search data')
          }
        } catch (error) {
          this.toastr.error(error)
        }
      })
    }
  }
}


