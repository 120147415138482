import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as $ from "jquery";
import { TranslateService } from '@ngx-translate/core';
import { Router } from "@angular/router"

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  Year:any=new Date().getFullYear()

  @Output() langEvent = new EventEmitter<string>();
  set_lang: any = '';
  showElement: any = false;
  constructor(
    private translate: TranslateService,
    private router: Router
  ) {
    translate.addLangs(['en', 'ch']);
    translate.setDefaultLang('en');
    this.set_lang = sessionStorage.getItem('set_lang');
    if (this.set_lang == '' || this.set_lang == undefined || this.set_lang == null) {
      translate.setDefaultLang('en');
    } else {
      translate.setDefaultLang(this.set_lang);
    }
  }

  public loadJsFile(url) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  public loadCSSFile(url) {
    let node = document.createElement('link');
    node.href = url;
    node.type = 'text/css';
    node.type = 'stylesheet';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  ngOnInit() {
    $("body").removeClass("diamond-search");
    $.noConflict();
    // this.loadJsFile("https://code.jquery.com/jquery-1.12.4.min.js");
    // this.loadJsFile("../../assets/BeforLogin/js/smoothscroll.js");
    // this.loadJsFile("../../assets/BeforLogin/js/popper.min.js");
    // this.loadJsFile("../../assets/BeforLogin/js/animsition.min.js");
    // this.loadJsFile("../../assets/BeforLogin/js/owl.carousel.min.js");
    // this.loadJsFile("../../assets/BeforLogin/js/wow.min.js");
    // this.loadJsFile("../../assets/BeforLogin/js/jquery.pagepiling.min.js");
    // this.loadJsFile("../../assets/BeforLogin/js/isotope.pkgd.min.js");
    // this.loadJsFile("../../assets/BeforLogin/js/jquery.fancybox.min.js");
    // this.loadJsFile("../../assets/BeforLogin/js/TweenMax.min.js");
    // this.loadJsFile("../../assets/BeforLogin/js/ScrollMagic.min.js");
    //this.loadJsFile("../../assets/BeforLogin/js/animation.gsap.min.js"); // already commented
    // this.loadJsFile("../../assets/BeforLogin/js/jquery.viewport.js");
    // this.loadJsFile("../../assets/BeforLogin/js/jquery.countdown.min.js");
    // this.loadJsFile("../../assets/BeforLogin/js/script.js");


  }

  ngAfterViewInit(){
    this.loadJsFile("../../assets/PSHNew/js/script.js");
  }


  // scrollToID(id, speed) {
  //   var offSet = 0;
  //   var targetOffset = $(id).offset().top - offSet;
  //   var mainNav = $('#main-nav');
  //   $('html,body').animate({ scrollTop: targetOffset }, speed);
  //   if (mainNav.hasClass("open")) {
  //     mainNav.css("height", "1px").removeClass("in").addClass("collapse");
  //     mainNav.removeClass("open");
  //   }
  // }


langtranslator(lan)
  {
    this.set_lang =lan;     sessionStorage.setItem('set_lang', lan);
    this.translate.use(lan);
    this.langEvent.next(lan);
  }

  redirectToLogin() {
    this.router.navigate(['/login'])
  }

  redirectToProduct() {
    this.router.navigate(['/products'])
  }

  redirectToQuotation(){
    this.router.navigate(['/quotation'])
  }

  redirectToIndex(){
    this.router.navigate(['/index'])
  }

  redirectToAboutUs(){
    this.router.navigate(['/aboutus'])
  }

  redirectToContactUs(){
    this.router.navigate(['/contact'])
  }

  redirectToTerms(){
    this.router.navigate(['/terms'])
  }

}

