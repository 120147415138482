import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChangePassowrdService {

  constructor(private http: HttpClient) { }

  private BaseUrl = environment.BaseUrl;


  CheckOldPassword(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'ChangePassowrd/CheckOldPassword',Data);
  }

  ChangePasswordsave(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'ChangePassowrd/ChangePasswordsave',Data);
  }

}
